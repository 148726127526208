import { Box, Grid, Paper, Typography,Alert, Button } from "@mui/material"
import { useEffect, useState } from "react"
import {  useParams } from "react-router"
import apis from "../../api"
import { RightDrawer, Title } from "../../Component/MuiEx"
import { apiResult, getUserSession } from "../../Utils/Common"

import ClassMemberList from "./fragement/ClassMemberList"
import EditSchedule from "./fragement/EditSchedule"
import EditClass from "./fragement/EditClass"

const ClassHome = (props) => {
    const params = useParams()
    const [error, setError] = useState()
    const [classobj, setClassObj] = useState()        
    const [settledFee,setSettledFee] = useState(0)
    const [dividedFee,setDividedFee] = useState(0)
    const [rightDrawer, setRightDrawer] = useState()
    const [autoCloseRightDraw, setAutoCloseRightDraw] = useState(true)
    getUserSession(apis)
    const loadData = ()=>{
        apis.classGet(params.id, 1).then(ret => {
            apiResult(ret, data => {
                setClassObj(data)                
            }, setError)
        })    
    }
    useEffect(() => {
        loadData()
    }, [])
    const handleMembersChange = (obj) => {
        setClassObj({...obj})
    }
    const handleLoaded = (data)=>{
        setSettledFee(data.settledFee)
        setDividedFee(data.dividedFee)
    }
    const handleAfterEdit = (data,err,errs) => {
        apis.classPut(data).then(ret => {
            apiResult(ret, (retdata) => {                
                setAutoCloseRightDraw(true)
                setRightDrawer(false)
                loadData()
            }, err, errs)
        })
    }
    const handleRightDrawer = () => {
        if (autoCloseRightDraw)
            setRightDrawer(false)
    }
    const handleEditClass = () => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditClass data={classobj} onSubmit={(data, err, errs) => { handleAfterEdit(data, err, errs) }} onClose={() => { setRightDrawer(false) }} />)
    }
    return <Box>
        {error && <Alert severity={"error"} onClose={() => setError()}>{error}</Alert>}
        <Grid container spacing={2}>
            <Grid item xs={3}>
                {classobj && <Paper sx={{ p: 2 }}>
                    <Title>{classobj.name}</Title>
                    <Typography variant="body" component={"p"}>{classobj.course.name}</Typography>
                    <Typography variant="body" component={"p"}>Teacher: {classobj.Teacher?.name}</Typography>
                    <Typography variant="body" component={"p"}>Assistant: {classobj.Assistant?.name}</Typography>
                    <Typography variant="body" component={"p"}>Begin date: {classobj.begindate}</Typography>
                    <Typography variant="body" component={"p"}>Lessons :{classobj.lessons}</Typography>                    
                    <Typography variant="body" component={"p"}>Students: {classobj.students}</Typography>
                    <Typography variant="body" component={"p"}>Fee: {classobj.fee}</Typography>
                    <Typography variant="body" component={"p"}>Divided Rate: {classobj.divided_rate}</Typography>
                    <Typography variant="body" component={"p"}>Term: {classobj.term}</Typography>
                    <Typography variant="body" component={"p"}>Schedule: {classobj.wday} / {classobj.times}</Typography>
                    <Typography variant="body" component={"p"}>Location: {classobj.atschool}</Typography>
                    <Typography variant="body" component={"p"}>Provider: {classobj.provider}</Typography>
                </Paper>}
                {classobj && <Paper sx={{ p: 2,mt:2 }}>
                    <Title>Fee</Title>
                    <Typography variant="body" component={"p"}>Settled Fee: ${settledFee?.toFixed(2)}</Typography>
                    <Typography variant="body" component={"p"}>Final Income: ${dividedFee?.toFixed(2)}</Typography>                    
                </Paper>}
                <Paper sx={{p:2,mt:2}}>
                    <Button onClick={handleEditClass}>Edit Class</Button>
                </Paper>
            </Grid>
            <Grid item xs={9}>
                {classobj && <ClassMemberList onLoaded={handleLoaded} all={true} onChange={handleMembersChange} classobj={classobj} classid={params.id} />}
            </Grid>
        </Grid>
        <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
    </Box>
}

export default ClassHome