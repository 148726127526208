
import AddIcon from '@mui/icons-material/Add';
import {EditOutlined} from '@mui/icons-material';
import DailySchedule from './fragement/DailySchedule';
import { MenuItem,  InputLabel,  Select, Box, Grid, CardHeader,  CardContent, Card, FormControl,  ListItemIcon, ListItemText, Alert, Paper, Toolbar, Typography,  Stack, Snackbar, Backdrop, IconButton, Chip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { apiResult, formatDate, getDatesInRange1, getUserSession,  } from "../../Utils/Common";
import apis from "../../api";
import {  RightDrawer } from "../../Component/MuiEx";
import { useNavigate, useParams } from "react-router";
import DownMenuItem from "../backup/fragement/DownMenuItem";
import EditSchedule from "./fragement/EditSchedule";
import {  ScheduleStatusDialog } from "./fragement/ModifyScheduleDialog";
import { CoachLoader, CoachSelect, getCoach } from "../fragement/CoachSelector";
import { getMember, MemberLoader, MemberSelect } from "../fragement/MemberAutoComplete";
import { ClassLoader, ClassSelect, getClass } from "../fragement/ClassSelector";
import { CourseLoader, getCourse } from "../fragement/CourseSelector";

const ClassSchedule = () => {
    const [loading, setLoading] = useState(false)
    const [rightDrawer, setRightDrawer] = useState()
    const [hintMsg, setHintMsg] = useState()
    const [schedules, setSchedules] = useState([])
    const [dates, setDates] = useState([])
    const [members, setMembers] = useState()
    const [coaches, setCoaches] = useState()
    const [courses,setCourses] = useState()
    const [classes,setClasses] = useState()
    const [currentDate, setCurrentDate] = useState()
    const params = useParams()
    const [field, setField] = useState({ member_id: params.member, teacher_id: params.teacher, assistant_id: params.assistant, class_id: params.class })
    const [error, setError] = useState()
    const [timeStamp, setTimeStamp] = useState()
    const [deleteSchedule, setDeleteSchedule] = useState()
    const [editSchedule, setEditSchedule] = useState()
    const navigate = useNavigate()
    const [autoCloseRightDraw, setAutoCloseRightDraw] = useState(true)
    const session = getUserSession(apis)
    const weeks = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
    const [holidays,setHolidays] = useState()
    const handleHintClose = () => {
        setHintMsg()
    }
    const inputRef = useRef()
    useEffect(() => {
        inputRef.current?.focus()
        if (inputRef.current) {
            inputRef.current.focus()
        }

    }, [timeStamp])
    const loadSchedule = (field) => {
        setLoading(true)
        apis.classschedules(field).then(ret => {
            setLoading(false)
            apiResult(ret, (data) => {
                loadData(field,data)
            }, setError)
        }) 
        let nowdate = new Date()        
        let year = field.year>0?Number(field.year):nowdate.getFullYear()
        let month = field.month>0?Number(field.month):nowdate.getMonth() + 1
        apis.holidayGet(year,month).then(ret=>{
            apiResult(ret,data=>{
                setHolidays(data)
            },setError)
        })       
    }
    const loadData = (field,data) => {
        let s = {}
        let nowdate = new Date()        
        let year = field.year>0?Number(field.year):nowdate.getFullYear()
        let month = field.month>0?Number(field.month):nowdate.getMonth() + 1
        let bdate = year + '-' + (month < 10 ? ('0' + month) : month) + '-01 08:00:00'
        let edate = (month == 12 ? (year + 1) : year) + '-' + (month == 12 ? '01' : (month < 9 ? ('0' + (month + 1)) : (month + 1))) + '-01'
        let dates = getDatesInRange1(bdate,edate)
        let d = new Date(bdate).getDay()        
        for(let i=0;i<d;i++) {
            dates.unshift('0')
        }
        setDates(dates)
        data.map(d => {
            // let ff = !d.from || d.from == null || new Date(d.from) < new Date(data.from) ? data.from : d.from
            // let tt = !d.to || d.to == null || new Date(d.to) > new Date(data.to) ? data.to : d.to
            // let wdates = getAllWeekDay(d.wod, ff, tt)
            //for (let k = 0; k < wdates.length; k++) {
            let dd = { ...d }
            //dd.members = [{ id: dd.id, mid: dd.member_id, schedule: dd }]
            if (s[dd.sdate]) {
                s[dd.sdate].push(dd)
            } else {
                s[dd.sdate] = [dd]
            }
        })
        setSchedules(s)
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        // if (!session) {
        //     window.location.href = '/account/coach'
        // }
        loadSchedule(field)
        CoachLoader((data)=>{
            setCoaches(data)
        },apis)
        MemberLoader((data)=>{
            setMembers(data)
        },apis)
        ClassLoader((data)=>{
            setClasses(data)
        },apis)
        CourseLoader((data)=>{
            setCourses(data)
        },apis)
    }, [])

    const handleAfterEdit = (idx, schedule) => {
        // let cc = [...schedules]
        // if (idx >= 0) {
        //     cc[idx] = schedule
        // } else {
        //     cc.push(schedule)
        // }
        // setSchedules(cc)
        setAutoCloseRightDraw(true)
        setRightDrawer(false)
        loadSchedule(field)
    }
    const handleRightDrawer = () => {
        if (autoCloseRightDraw)
            setRightDrawer(false)
    }

    const handleAddSchedule = () => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditSchedule schedule={{ id: 0 }} onAfterEdit={(data) => { handleAfterEdit() }} onClose={() => { setRightDrawer(false) }} />)
    }
    const handleDelete = (lesson, idx) => {
        // window.confirm('Are you sure to delete the schedule') && apis.scheduleStatus(lesson.id, 0).then(ret => {
        //     apiResult(ret, data => {
        //         loadSchedule(field.coach_id, field.member_id, field.from)
        //     }, setError)
        // })        
        setDeleteSchedule({ ...lesson, type: 'member' })
    }
    const handleDeleteLesson = async (lesson) => {
        // if (window.confirm('Are you sure to delete the schedule')) {
        //     for (let i = 0; i < lesson.members.length; i++) {
        //         let m = lesson.members[i]
        //         await apis.scheduleStatus(m.id, 0)
        //     }
        //     loadSchedule(field.coach_id, field.member_id, field.from)
        // }        
        setDeleteSchedule({ ...lesson, type: 'plan' })
    }
    const handleDeleteSchedule = (option) => {
        let data = { id: deleteSchedule.id, type: deleteSchedule.type, option: option }
        apis.deleteSchedule(data).then(ret => {
            apiResult(ret, data => {
                loadSchedule(field)
                setDeleteSchedule()
            }, setError)
        })
    }
    const handleEdit = (lesson, idx) => {
        // window.confirm('Are you sure to delete the schedule') && apis.scheduleStatus(lesson.id, 0).then(ret => {
        //     apiResult(ret, data => {
        //         loadSchedule(field.coach_id, field.member_id, field.from)
        //     }, setError)
        // })        
        setEditSchedule({ ...lesson, type: 'member' })
    }
    const handleEditLesson = async (lesson) => {
        // if (window.confirm('Are you sure to delete the schedule')) {
        //     for (let i = 0; i < lesson.members.length; i++) {
        //         let m = lesson.members[i]
        //         await apis.scheduleStatus(m.id, 0)
        //     }
        //     loadSchedule(field.coach_id, field.member_id, field.from)
        // }        
        setEditSchedule(lesson)
    }
    const handleEditSchedule = (data) => {
        apis.classschedulePut(data).then(ret => {
            apiResult(ret, data => {
                loadSchedule(field)
                setEditSchedule()
            }, (err) => { alert(err) })
        })
    }
    const handleChange = (key, value) => {
        let tt = { ...field, [key]: value }
        setField(tt)
        loadSchedule(tt)
    }
    // const handleGo = () => {
    //     loadSchedule(field.coach_id ? field.coach_id : 0, field.member_id ? field.member_id : 0, field.from ? field.from : undefined)
    // }
    const setTextInputRef = (element) => {
        inputRef.current = element;
    };
    const OptionButton = session.isCoach ? [] : [
        { text: "Add", icon: <AddIcon fontSize="small" />, onClick: handleAddSchedule },
    ]

    const handleDateClick = (data) => {
        // let coachs = {}
        // data.schedule.map(lesson => {
        //     let coach = getCoachById(lesson.coach_id)
        //     lesson.cname = coach.name
        //     if (coachs["coach_" + coach.id]) {
        //         coachs["coach_" + coach.id].lessons.push(lesson)
        //     } else {
        //         coachs["coach_" + coach.id] = { coach: coach, lessons: [lesson] }
        //     }
        // })
        // data.schedule = coachs
        // setCurrentDate(data)
    }
    const handleShowMember = (id) => {

        // if (id == '*') {
        //     setShowMember([id])
        // } else if (id == undefined) {
        //     setShowMember([])
        // } else {
        //     let i = showMember.indexOf(id)
        //     if (i >= 0) {
        //         let ss = [...showMember]
        //         ss.splice(i, 1)
        //         setShowMember(ss)
        //     } else {
        //         setShowMember([...showMember, id])
        //     }
        // }
    }
    const curYear = new Date().getFullYear()
    const curMonth = new Date().getMonth()+1
    const getHoliday = (date) => {
        if(!holidays) return false
        let hs = []
        for(let h of holidays) {
            if(h.begin <= date && h.end>=date) {
                hs.push(h)
            }
        }
        if(hs.length>0) {
            let ret = hs[0]
            for(let h of hs) {
                if(h.type < ret.type) {
                    ret = h
                }
            }
            return ret
        }        
        return false
    }
    const holidayColors = ['#B22222','#006400','#32CD32','#B22222']
    return (
        <Box sx={{ p: 1 }}>
            <Backdrop open={loading} />
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2, p: 1 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    {coaches && <CoachSelect sx={{mr:2}} coaches={coaches} onChange={(name,item)=>{handleChange(name,item.id)}} name="teacher_id" label="Select teacher/Assistant"/>}                    
                    {classes && <ClassSelect sx={{mr:2}} classes={classes} onChange={(name,item)=>{handleChange(name,item.id)}} name="class_id" label="Select class"/>}
                    {members && <MemberSelect sx={{ mr: 2}} members={members} onChange={(name,item)=>{handleChange(name,item.id)}} name="member_id" label="Select member"/>}
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select year</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { handleChange('year', e.target.value) }}
                            defaultValue={curYear}
                            label="Select year" >
                            {[curYear-1,curYear,curYear+1,curYear+2].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mr: 2 }}>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select month</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { handleChange('month', e.target.value) }}
                            defaultValue={curMonth}
                            label="Select month" >
                            {[1,2,3,4,5,6,7,8,9,10,11,12].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {/* <FormControl sx={{ width: '300px', mr: 2 }}>
                        <TextField margin="normal" sx={{ mt: "8px" }} type="date" name="from" onChange={(e) => { handleChange('from', e.target.value) }} id="from" label="After date" InputLabelProps={{ shrink: true }} />
                    </FormControl> */}


                    <Typography sx={{ flex: '1 1 20%' }} variant="h6" component="div" ></Typography>
                    {/* <FormControlLabel sx={{ display: { xs: 'none', sm: 'flex' } }} control={<Switch defaultChecked={false} onChange={(e) => {
                        handleShowMember(e.target.checked ? "*" : undefined)
                    }} />} label="Member" /> */}
                    {<Stack direction={{ xs: 'column', sm: 'row' }}>
                        {OptionButton.map((item, index) => {
                            return item.subItems ? <DownMenuItem key={index} icon={item.icon} onClick={item.onClick} items={item.subItems} text={item.text} /> :
                                <MenuItem key={index} onClick={item.onClick}>
                                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}<ListItemText>{item.text}</ListItemText>
                                </MenuItem>
                        })}
                    </Stack>}

                </Toolbar>
            </Paper>
            {schedules.length == 0 && <Alert severity={"info"}>Ops! There is not any data</Alert>}
            <Box>
                <Grid container spacing={1}>
                    {dates.map((item, index) => {
                        const data = schedules[item] ? schedules[item] : []
                        const h = getHoliday(item)
                        const bgcolor = h ? holidayColors[h.type]:'#fff'                        
                        const today = formatDate(new Date())
                        //console.log([item,new Date(item),new Date(item).getDay(),weeks]) 
                        return <Grid key={index} item xs={12} sm={4} md={12 / 7} >
                            {item == '0' ? <></>:<Card key={index}>
                                <CardHeader onClick={() => { handleDateClick({ date: item, schedule: data }) }} sx={{ padding: '5px !important', cursor: 'pointer', bgcolor:today == item?'#059':'#059a', color: '#fff' }} title={item}
                                    avatar={<Box sx={{ bgcolor: today==item?'#ff5722':'#fff', color: today == item?'#fff':'#ff5722', width: 25, height: 25, p: "5px", borderRadius: "15px" }} size={"small"} fontSize={"small"} aria-label="recipe">{weeks[new Date(item + ' 12:00:00').getDay()]}</Box>} />
                                <CardContent sx={{ minHeight: 150, padding: '5px !important' }}>
                                    {h && <Typography variant='body2' sx={{color:bgcolor,fontSize:"10px"}}>{h.name}</Typography>}
                                    {classes && courses && data.map((lesson, index1) => {
                                        let p = lesson.class_id > 0 ? getClass(classes,lesson.class_id):(lesson.course_id>0?getCourse(courses,lesson.course_id):{name:'unkown'})
                                        lesson.pname = p.name
                                        let teacher = lesson.teacher_id>0?getCoach(coaches,lesson.teacher_id):undefined
                                        let assistant = lesson.assistant_id>0?getCoach(coaches,lesson.assistant_id):undefined
                                        return <Paper key={index1} sx={{ p: 0.5, mb: '2px', bgcolor: lesson.member_id>0 ? '#f001' : '#0001' }} onClick={(e) => { e.stopPropagation(); handleShowMember(lesson.id); return false; }}>
                                            <Stack direction={'row'} title={p?.name}>
                                                <Typography variant="h6" color="text.secondary" sx={{ fontSize: '10px', color: '#000', fontWeight: 'bold', marginRight: '6px' }}>{Math.floor(lesson.begintime / 60) + ":" + (lesson.begintime % 60 < 10 ? ('0' + lesson.begintime % 60) : (lesson.begintime % 60))}~{Math.floor((lesson.begintime + lesson.duration) / 60) + ":" + ((lesson.begintime + lesson.duration) % 60 < 10 ? ('0' + (lesson.begintime + lesson.duration) % 60) : ((lesson.begintime + lesson.duration) % 60))}</Typography>                                                
                                                <Typography sx={{ flex: '1 1 100%' }}></Typography>
                                                {/* <Delete sx={{ fontSize: '12px', color: '#0003', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); handleDeleteLesson(lesson); return false; }} /> */}
                                                {lesson.status == 1 && <EditOutlined sx={{ fontSize: '12px', color: '#0003', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); handleEditLesson(lesson); return false; }} />}
                                            </Stack>
                                            <Typography title={p?.name} variant="h6" color="text.secondary" sx={{ fontSize: '10px', fontWeight: 'bold', overflow: 'hidden', maxHeight: '12px', flex: '1 1 100%' }}>{p?.name}-Lesson {lesson.lesson}</Typography>
                                            {lesson.member_id>0 && <Chip size={'small'} color={'default'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={getMember(members,lesson.member_id)?.name} /> }
                                            {teacher && <Chip size={'small'} color={'default'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={teacher.name +"/"+assistant?.name} />}
                                            {lesson.status == 2 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"postpone"} onDelete={()=>{
                                                handleEditSchedule({id:lesson.id,status:1})
                                            }}/>}
                                            {lesson.status == 5 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"holiday"} onDelete={()=>{
                                                handleEditSchedule({id:lesson.id,status:1})
                                            }}/>}                                                                                         
                                            {lesson.status == 3 && <Chip size={'small'} color={'warning'} variant={"outlined"} sx={{ p: 0, fontSize: '10px', height: '18px', lineHeight: '1' }} label={"Canceled"} onDelete={()=>{
                                                handleEditSchedule({id:lesson.id,status:1})
                                            }}/>}                                           
                                            {lesson.note && lesson.note.length>0 && <Alert icon={false} severity="info">{lesson.note}</Alert>}
                                        </Paper>
                                    })}
                                </CardContent>
                            </Card>}</Grid>
                    })}
                </Grid>

            </Box>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {currentDate && <DailySchedule dateSchedule={currentDate} onClose={() => { setCurrentDate() }}></DailySchedule>}
            {/* {deleteSchedule && <DeleteScheduleDialog schedule={deleteSchedule} onClose={() => { setDeleteSchedule() }} onSubmit={handleDeleteSchedule} />} */}
            {editSchedule && <ScheduleStatusDialog schedule={editSchedule} onClose={() => { setEditSchedule() }} onSubmit={handleEditSchedule} />}
        </Box>
    )
}
export default ClassSchedule