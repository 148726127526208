import MyTable from "../../Component/MyTable"
import AddIcon from '@mui/icons-material/Add';
import { MenuItem, ListItemIcon, ListItemText, Alert, Paper, Toolbar, Typography, Button, Stack, Snackbar, Backdrop, ButtonGroup, FormControl,InputLabel,Select } from "@mui/material";
import { useEffect, useState } from "react";
import { apiResult, getUserSession, sessionSet, } from "../../Utils/Common";
import apis from "../../api";
import { RightDrawer } from "../../Component/MuiEx";
import { useNavigate, useParams } from "react-router";
import DownMenuItem from "../backup/fragement/DownMenuItem";
import EditClass from "./fragement/EditClass";
import { CourseCode } from "../../Utils/Dict";

const Classes = () => {
    const [loading, setLoading] = useState(false)
    const [rightDrawer, setRightDrawer] = useState()
    const [hintMsg, setHintMsg] = useState()
    const [datas, setDatas] = useState([])
    const [terms, setTerms] = useState()
    const [curTerm,setCurTerm] = useState("None")
    const [curDay,setCurDay] = useState("None")
    
    const [curClass,setCurClass] = useState("All")
    const [error, setError] = useState()
    const navigate = useNavigate()
    const [autoCloseRightDraw, setAutoCloseRightDraw] = useState(true)
    const session = getUserSession(apis)
    const params = useParams()
    const handleHintClose = () => {
        setHintMsg()
    }
    const loadDatas = (status, classtype, term,wday) => {
        setLoading(true)
        apis.classes(status, classtype, term,wday).then(ret => {
            apiResult(ret, (r) => {
                setDatas(r)
                if(!term) {
                    let tt = []
                    r.map(item => {
                        if(!item.term || item.term == null || item.term == "") {
                            item.term = "None"
                        }
                        if (tt.indexOf(item.term) < 0) {
                            tt.push(item.term)
                        }
                    })
                    setTerms(tt)
                }
            }, setError)
            setLoading()
        })
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadDatas(undefined, undefined, undefined,undefined)
    }, [])

    const handleAfterEdit = (idx, data, err, errs) => {
        idx == -1 ? apis.classPost(data).then(ret => {
            apiResult(ret, (id) => {
                sessionSet('classes_list', null)
                setAutoCloseRightDraw(true)
                setRightDrawer(false)
                loadDatas(undefined, curClass, curTerm,curDay)
            }, err, errs)
        }) : apis.classPut(data).then(ret => {
            apiResult(ret, (retdata) => {
                sessionSet('classes_list', null)
                setAutoCloseRightDraw(true)
                setRightDrawer(false)
                loadDatas(undefined, curClass, curTerm,curDay)
            }, err, errs)
        })
    }
    const handleRightDrawer = () => {
        if (autoCloseRightDraw)
            setRightDrawer(false)
    }

    const handleEdit = (idx, item) => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditClass data={item} onSubmit={(data, err, errs) => { handleAfterEdit(idx, data, err, errs) }} onClose={() => { setRightDrawer(false) }} />)
    }
    const handleAdd = () => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditClass data={{ id: 0 }} onSubmit={(data, err, errs) => { handleAfterEdit(-1, data, err, errs) }} onClose={() => { setRightDrawer(false) }} />)
    }
    const handleDelete = (idx, item) => {
        apis.classDelete(item.id).then(ret => {
            apiResult(ret, data => {
                let cc = [...datas]
                cc[idx].status = 0
                setDatas(cc)
            }, setError)
        })
    }
    const handleEnable = (idx, item) => {
        apis.classPut({ id: item.id, status: 1 }).then(ret => {
            apiResult(ret, data => {
                let cc = [...datas]
                cc[idx].status = 1
                setDatas(cc)
            }, setError)
        })
    }
    const handleView = (idx, item) => {
        window.open('/class/class/' + item.id)
    }
    const TableHeader = [
        { name: 'id', showName: 'ID' },
        { name: 'name', showName: 'Class',func:(v,idx,item)=>{
            return <Stack direction={"column"}>
                <Typography variant="h6">{item.classtype?.toUpperCase()} | {v}</Typography>
                <Typography variant="body2" sx={{color:"#8888"}}>{item.course?.name}</Typography>
            </Stack>
        } },
        {
            name: 'teacher_id', showName: 'Teacher', func: (v, idx, item) => {
                return item.Teacher?.name
            }
        },
        // { name: 'assistant_id',showName:'Assistant',func:(v,idx,item)=>{
        //     return item.Assistant? (item.Assistant.name+"("+item.assistant_fee+")"):''
        // }},
        { name: 'students', showName: 'Students' },
        { name: 'begindate', showName: 'Term',func:(v,idx,item)=>{
            return (item.term != "None"?item.term:"")+" ("+v.substring(5,10)+")"
        }},
        { name: "wday",showName:"Schedule",func:(v,idx,item)=>{
            return v + (item.times?("("+item.times+")"):"")
        }},
        { name: 'lessons', showName: 'Lessons' },
        { name: 'atschool', showName: 'Location' },
        { name: 'provider', showName: 'Provider' },
        { name: 'note', showName: 'Note' },
        {
            name: 'option', showName: 'Options', func: (v, idx, item) => {
                return <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
                    <Button variant="outlined" onClick={() => handleEdit(idx, item)}>Edit</Button>
                    <Button variant={item.status == 0 ? "outlined" : "contained"} onClick={() => item.status != 0 ? handleDelete(idx, item) : handleEnable(idx, item)}>{item.status == 0 ? 'Disable' : 'Enable'}</Button>
                    <Button variant="outlined" onClick={() => handleView(idx, item)}>View</Button>
                </ButtonGroup>
            }
        },
    ]
    const OptionButton = [
        { text: "Add", icon: <AddIcon fontSize="small" />, onClick: handleAdd },
    ]
    return (
        <>
            <Backdrop open={loading} />
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }}>
                        {OptionButton.map((item, index) => {
                            return item.subItems ? <DownMenuItem key={index} icon={item.icon} onClick={item.onClick} items={item.subItems} text={item.text} /> :
                                <MenuItem key={index} onClick={item.onClick}>
                                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}<ListItemText>{item.text}</ListItemText>
                                </MenuItem>
                        })}
                    </Stack>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" ></Typography>
                    <FormControl sx={{width:250,mr:1}}>
                        <InputLabel id="select-term">{"Select Term"}</InputLabel>
                        <Select labelId="select-term"
                            id="term" onChange={(e) => { setCurTerm(e.target.value);loadDatas(undefined, curClass, e.target.value,curDay); }}
                            label={"Select Term"}>
                            {terms && terms.map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width:250,mr:1}}>
                        <InputLabel id="select-course">{"Select Course"}</InputLabel>
                        <Select labelId="select-course"
                            id="classtype" onChange={(e) => { setCurClass(e.target.value);loadDatas(undefined, e.target.value,curTerm,curDay) ; }}
                            label={"Select Course"}>
                            {[...CourseCode,"All"].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width:250}}>
                        <InputLabel id="select-term">{"Select Day"}</InputLabel>
                        <Select labelId="select-wday"
                            id="wday" onChange={(e) => { setCurDay(e.target.value);loadDatas(undefined, curClass,curTerm, e.target.value); }}
                            label={"Select Week Day"}>
                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday","None"].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Toolbar>
            </Paper>
            {datas.length == 0 && <Alert severity={"info"}>Ops! There is not any data</Alert>}
            {datas.length > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={datas.length}
                headers={TableHeader}
                checkbox={false}
                rowsPerPage={datas.length}
            />}
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
        </>

    )
}
export default Classes