import { useEffect, useState } from 'react';
import { Button, TextField, Grid, Box, Typography, Alert,FormControl,InputLabel,Select,MenuItem } from '@mui/material';
import { apiResult, formatDate, formToJson, getUserSession } from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import apis from '../../../api';

const Refund = (props) => {
    const { onSubmit, onClose,item } = props
    const [fieldErrors, setFieldErrors] = useState()
    const [balances, setBalances] = useState([])
    const [fields,setFields] = useState({action:'charge',typeid:0,amount:item.remained_lessons})
    const [error, setError] = useState()
    const [refundAmount,setRefundAmount] = useState()
    getUserSession(apis)
    // const session = getUserSession(apis)    
    useEffect(() => {
        apis.getBalance(0,item.member_id).then(ret => {
            apiResult(ret, data => {
                setBalances(data)                
            }, setError)
        })
    }, [item])
    const handleChange = (k,v) => {
        let dd = {...fields,[k]:v}
        setFields(dd)
        if(dd.typeid>0 && dd.amount>=0) {
            for(let i=0;i<balances.length;i++) {
                if(balances[i].id == dd.typeid) {
                    balances[i].rate > 0? setRefundAmount(Number(dd.amount)*item.lesson_price/Number(balances[i].rate)):setRefundAmount(0)
                }
            }
        }
    }
    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.typeid = fields.typeid
        postData.member_id = item.member_id
        postData.class_id = item.class_id
        postData.action = 'refundclass'
        if(postData.typeid == 0) {
            setError('Must choice a balance type')
            return;
        }
        if(postData.amount <= 0 ) {
            setFieldErrors({...fieldErrors,amount:'Must input lesson amount'})
            return;
        }        
        apis.updateBalance(postData).then(ret=>{apiResult(ret,data=>{
            onSubmit && onSubmit()
        },setError)})
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Refund the course</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={props.sx}>
                                <InputLabel id="demo-simple-select-autowidth-label">{"Select refund to"}</InputLabel>
                                <Select labelId="demo-simple-select-autowidth-label" id="typeid"
                                    onChange={(e) => {                                        
                                        handleChange('typeid',e.target.value)
                                    }}                                    
                                    label={"Select balance"}
                                >{balances && balances.map((p, idx) => {                                        
                                        return p.rate>0 && p.refundable == 0 && <MenuItem key={idx} value={p.id}>{p.type + (p.user_balances && p.user_balances.length>0?(" (Balance: "+p.user_balances[0].balance+")"):'')}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number" label="Refund amount" fullWidth disabled value={refundAmount} InputLabelProps={{ shrink: true }}  />
                        </Grid>                   
                        <Grid item xs={12} sm={6}>
                            <TextField type="number" label="Lesson amount to refund" name="amount" id="amount" defaultValue={item.remained_lessons} fullWidth required onChange={(e)=>handleChange('amount',e.target.value)}
                                error={fieldErrors && fieldErrors.amount ? true : false}
                                helperText={fieldErrors && fieldErrors.amount ? fieldErrors.amount : ''} />
                        </Grid>
                             
                        <Grid item xs={12} sm={6}>
                            <TextField type="date" label="Date" name="sdate" id="sdate" fullWidth defaultValue={formatDate(new Date())}
                                error={fieldErrors && fieldErrors.sdate ? true : false}
                                helperText={fieldErrors && fieldErrors.sdate ? fieldErrors.sdate : ''} />
                        </Grid>         
                        <Grid item xs={12} sm={12}>
                            <TextField type="text" label="Note" name="note" id="note" fullWidth onChange={(e)=>handleChange('note',e.target.value)}
                                error={fieldErrors && fieldErrors.note ? true : false}
                                helperText={fieldErrors && fieldErrors.note ? fieldErrors.note : ''} />
                        </Grid>               
                    </Grid>
                    {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { onClose && onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default Refund