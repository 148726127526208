import { useState } from 'react';
import { Typography, Avatar, Autocomplete, Select, Container, CssBaseline, FormControl, InputLabel, MenuItem, Alert, TextField, Button, Box, Paper } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { apiResult, formToJson, getUserSession, } from '../../../Utils/Common';
import apis from '../../../api';
import { MemberLevel, MultiSelector } from '../../../Component/MuiEx';
import { ClassSelect } from '../../fragement/ClassSelector';
import { MemberSelect } from '../../fragement/MemberAutoComplete';
import { CoachSelect } from '../../fragement/CoachSelector';
import { CourseSelect } from '../../fragement/CourseSelector';

const EditSchedule = (props) => {
    let { onAfterEdit, onClose,classid } = props
    const [error, setError] = useState()
    const [field, setField] = useState({wods:[],dates:[],blesson:1,elesson:20,class_id:classid})
    const [classobj,setClassObj] = useState()
    getUserSession(apis)
    const handleSubmit = async () => {
        setError()
        
        if (!field.begintime) {
            setError("You need to set the start time")
            return
        }
        if (!field.duration || field.duration < 5) {
            setError("You need to set minutes (multiple of 15 minutes)")
            return
        }
        if(Number(field.begintime) + Number(field.duration) > 1380) {
            setError('Time is over than 23:00')
            return
        }
        if(field.class_id > 0) {
            let wods = field.wods
            if ((!wods || wods.length == 0) && (!field.dates || field.dates.length == 0)) {
                setError("You need to select one day of week or input some date")
                return
            }
            if(!field.blesson || field.blesson == 0) {
                setError("You need to set a from-lesson-no.")
                return
            }
            if(!field.elesson || field.elesson == 0) {
                setError("You need to set a to-lesson-no.")
                return
            }
        }else {
            if(!field.teacher_id || field.teacher_id == 0) {
                setError("You need to select a teacher")
                return
            }
            if(!field.course_id || field.course_id == 0) {
                setError("You need to select a course")
                return
            }
            if(!field.blesson || field.blesson == 0) {
                setError("You need to set a lesson")
                return
            }
            if (!field.dates || field.dates.length == 0) {
                setError("You need to input some dates")
                return
            }            
        }
        apis.classschedulePost(field).then(ret=>{
            apiResult(ret,data=>{
                onAfterEdit(data)
            },setError)
        })
    }
    const handleChange = (k, v) => {
        setField({ ...field, [k]: v,member_id:(k=='class_id'&&v>0)?0:(k == 'member_id'?v:field.member_id) })        
    }
    const timeToMinutes = (v) =>{
        let t = v.split(':')
        if(t.length == 2) {
            return Number(t[0])*60 + Number(t[1])
        }else {
            return 0
        }
    }
    const handleDates = (v) =>{
        if(v && v.length>0) {
            let dates = v.split(',')
            setError()            
            for(let i=0;i<dates.length;i++) {                
                if(isNaN(Date.parse(dates[i]))) {
                    setError("The date is invalid")
                    return
                }
            }
            handleChange('dates', dates)
        }
    }
    return <><Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
            <Typography component="h1" variant="h5"> Add & Edit Schedule </Typography>
            <Box sx={{ mt: 5 }}>
                <ClassSelect defaultValue={classid} name="class_id" sx={{mb:1}} onChange={(name,item)=>{setClassObj(item);handleChange(name,item.id)}} label="Select class"/>
                {field.class_id == 0 && <MemberSelect sx={{mb:1}} name="member_id" onChange={(name,item)=>{handleChange(name,item.id)}} label="Select member"/>}
                {field.class_id>0 && <TextField margin="normal" type="date" defaultValue={classobj?.begindate} onChange={(e)=>{handleChange('begindate',e.target.value)}} fullWidth label={"Begin date ("+classobj?.begindate+")"} InputLabelProps={{ shrink: true }} />}
                {field.class_id == 0 && <CoachSelect sx={{mb:1}} name="teacher_id" onChange={(name,item)=>{handleChange(name,item.id)}} label="Select teacher"/>}
                {field.class_id == 0 && <CoachSelect sx={{mb:1}} name="assistant_id" onChange={(name,item)=>{handleChange(name,item.id)}} label="Select assistant"/>}
                {field.class_id == 0 && <CourseSelect sx={{mb:1}} name="course_id" onChange={(name,item)=>{handleChange(name,item.id)}} label="Select course"/>}
                <TextField margin="normal" type="number" name="blesson" onChange={(e) => { handleChange('blesson', e.target.value) }} defaultValue={1} fullWidth label="From Lesson No." InputLabelProps={{ shrink: true }} />
                {field.class_id > 0 && <TextField margin="normal" type="number" name="elesson" onChange={(e) => { handleChange('elesson', e.target.value) }} defaultValue={20} fullWidth label="To Lesson No." InputLabelProps={{ shrink: true }} />}                
                {field.class_id > 0 && <MultiSelector items={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']} values={[0, 1, 2, 3, 4, 5, 6]} name="wods" onChange={handleChange} label="Day of week" />}
                <TextField margin="normal" type="text" name="dates" onChange={(e) => { handleDates(e.target.value) }} fullWidth label="Dates(like 2022-11-20,2022-12-01..." InputLabelProps={{ shrink: true }} />
                <TextField fullWidth margin="normal" type="time" min="06:00" max="22:00" name="begintime" onChange={(e) => { handleChange('begintime', timeToMinutes(e.target.value)) }} label="Start time" InputLabelProps={{ shrink: true }} />
                <TextField fullWidth margin="normal" type="number" name="minutes" onChange={(e) => { handleChange('duration', e.target.value) }} label="Minutes" />
                {error && <Alert severity="error">{error}</Alert>}
                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit} >Add</Button>
                <Button fullWidth variant="outlined" onClick={() => { onClose && onClose(false) }} sx={{ mb: 2 }} > Cancel </Button>
            </Box>
        </Box>
    </Container></>
}

export default EditSchedule