import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../../api"
import { apiResult, getUserSession, sessionGet, sessionSet } from "../../Utils/Common"


const CourseSelect = (props) => {
    const { defaultValue,onChange,courses,name,label} = props
    const [_courses,setCourses] = useState(courses)
    getUserSession(apis)
    useEffect(()=>{
        if(!_courses) {
            CourseLoader((data)=>{setCourses(data)},apis)
        }
    },[])
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label ?label:"Select course"}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id="product"
            onChange={(e) => { 
                let id = e.target.value; 
                let choice = false
                if(id>0) {
                    for(let i=0;i<_courses.length;i++) {
                        if(id == _courses[i].id) {
                            onChange && onChange(name,_courses[i])
                            choice = true
                            break
                        }
                    }
                }
                if(!choice) {
                    onChange && onChange(name,{id:0})
                }
            }}
            defaultValue={defaultValue}
            label={label ?label:"Select course"}
        >
            <MenuItem value={0}>Nothing</MenuItem>
            {_courses && _courses.map((p, idx) => {
                return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}
const CourseLoader = (callback, apis) => {
    let courseslist = sessionGet('courses_list')
    if (courseslist === null) {
        apis.courses(1).then(ret => {
            apiResult(ret, data => {
                sessionSet('courses_list',data)
                callback(data)
            })
        })
    } else {
        callback(courseslist)
    }
}
const getCourse = (courses,id)=>{
    for(let i=0;i<courses.length;i++) {
        if(courses[i].id == id) {
            return courses[i]
        }
    }
    return undefined
}
export {CourseLoader,CourseSelect,getCourse}