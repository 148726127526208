import MyTable from "../../Component/MyTable"
import { MenuItem, ListItemIcon, ListItemText, Alert, Paper, Toolbar, Typography, Button, Stack, Snackbar, Backdrop,  ButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { apiResult,  getUserSession,  } from "../../Utils/Common";
import apis from "../../api";
import {  useNavigate, useParams } from "react-router";
import DownMenuItem from "../backup/fragement/DownMenuItem";
import { NavLink } from "react-router-dom";
import { More, MoreOutlined } from "@mui/icons-material";

// import EditRole from "./fragement/EditRole";
const ClassApplication = () => {
    const [loading, setLoading] = useState(false)    
    const [hintMsg, setHintMsg] = useState()
    const [datas, setDatas] = useState([])        
    const [error, setError] = useState()
    const navigate = useNavigate()
    const session = getUserSession(apis)
    const params = useParams()
    const handleHintClose = () => {
        setHintMsg()
    }
    const loadDatas = () => {
        setLoading(true)       
        apis.classApplicantGet(params.id?params.id:0,params.ccid?params.ccid:0).then(ret => {
            setLoading(false)
            apiResult(ret, (data) => {                
                setDatas(data)
            }, setError)
        })
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadDatas()
    }, [params])

   
    const handleStatus = (idx, field,status) => {
        apis.classApplicantPut({ id: datas[idx].id,[field]: status }).then(ret => {
            apiResult(ret, data => {
                let cc = [...datas]
                cc[idx][field] = status
                setDatas(cc)
            }, setError)
        })
    }
    
    // const handleView = (idx, item) => {
    //     navigate('/website/classnew/'+item.id)
    // }
    const TableHeader = [
        { name: 'id', showName: 'ID' },
        { name: 'name',showName:"Applicant",func:(v,idx,row)=>{return <><font style={{fontWeight:"bold"}}>{v}</font><br></br>{row.phone}<br></br>{row.email}</>}},
        { name: 'kid_name',showName:"Kid",func:(v,idx,row)=>{return <><font style={{fontWeight:"bold"}}>{v}</font><br></br>{row.kid_gender}<br></br>{row.kid_school}({row.kid_grade})</>}},
        { name: 'course', showName: 'Course',func:(v,idx,row)=>{return <NavLink to={"/website/classapplicant/"+row.class_new?.course_category?.id+"/0"}>{row.class_new?.course_category?.name}</NavLink>}},
        { name: 'name', showName: 'Class',func:(v,idx,row)=>{return <><NavLink to={"/website/classapplicant/0/"+row.class_new?.id}>{row.class_new?.name}</NavLink><br></br>{row.class_new?.level}</>}},        
        { name: 'wdate', showName: 'Week day',func:(v,idx,row)=>{return row.class_new?.wdate+"("+row.class_new?.times+")"}},
        { name: 'bdate', showName: 'Begin',func:(v,idx,row)=>{return row.class_new?.bdate}},
        { name: 'lessons', showName: 'Lesson',func:(v,idx,row)=>{return row.class_new?.lessons+"("+row.class_new?.price+")"}},
        {
            name: 'option', showName: 'Options', func: (v, idx, item) => {
                return <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">                    
                    <Button variant={item.status == 0 ? "outlined" : "contained"} onClick={() => item.status != 0 ? handleStatus(idx,"status",0) : handleStatus(idx,"status",1) }>{item.status == 0 ? 'Disable' : 'Enable'}</Button>
                    <Button variant={item.bstatus == 0 ? "outlined" : "contained"} onClick={() => item.bstatus != 0 ? handleStatus(idx,"bstatus",0) :handleStatus(idx,"bstatus",1) }>{item.bstatus == 0 ? 'Notify' : 'Notified'}</Button>
                    {/* <Button variant="outlined" onClick={() => handleView(idx, item)}>Courses</Button>                     */}
                </ButtonGroup>
            }
        },
        { name: 'create_time',showName:"Date",func:(v,idx,row)=>{return v.substring(0,19).replace("T"," ")}},
        
    ]    
    const OptionButton = [
        { text: "All Classes", icon: <MoreOutlined fontSize="small" />, onClick: ()=>{navigate("/website/classapplicant/0/0")} },
    ]
    return (
        <>
            <Backdrop open={loading} />
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }}>
                        {OptionButton.map((item, index) => {
                            return item.subItems ? <DownMenuItem key={index} icon={item.icon} onClick={item.onClick} items={item.subItems} text={item.text} /> :
                                <MenuItem key={index} onClick={item.onClick}>
                                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}<ListItemText>{item.text}</ListItemText>
                                </MenuItem>
                        })}
                    </Stack>
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" ></Typography>
                </Toolbar>
            </Paper>
            {datas.length == 0 && <Alert severity={"info"}>Ops! There is not any data</Alert>}
            {datas.length > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={datas.length}
                headers={TableHeader}
                checkbox={false}
                rowsPerPage={datas.length}
            />}
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </>

    )
}
export default ClassApplication