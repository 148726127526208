import MyTable from "../../Component/MyTable"
import {  TextField, FormControl, Alert, Paper, Toolbar, Typography, Button,  Snackbar,   ButtonGroup, Box, IconButton, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { apiResult, formatDate, getCurrentMonth01, getUserSession, } from "../../Utils/Common";
import apis from "../../api";
import { RightDrawer, } from "../../Component/MuiEx";

import { useNavigate, useParams } from "react-router";
import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../App/config";
import { CreditCard, CreditCardOff, Mood, MoodBad, Payment, People, PriceCheck, Redeem } from "@mui/icons-material";
const TeachRecords = () => {
    const params = useParams()
    const [hintMsg, setHintMsg] = useState()
    const coachid = useRef(params.coachid)
    const [datas, setDatas] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [title, setTitle] = useState()
    const [error, setError] = useState()
    const [fields, setFields] = useState({ begin: getCurrentMonth01(), end: formatDate(new Date()), action: 'all' })
    const [rightDrawer, setRightDrawer] = useState()   //open or close right drawer
    const [autoCloseRightDrawer, setAutoCloseRightDrawer] = useState(true)
    const [totalFee,setTotalFee] = useState(0)
    const [settledFee,setSettledFee] = useState(0)
    const [showFee,setShowFee] = useState(false)
    const navigate = useNavigate()
    getUserSession(apis)
    // const navigate = useNavigate()

    const handleHintClose = () => {
        setHintMsg()
    }
    const loadData = (page, pagesize, countData) => {
        //setLoading(true)
        setError()
        apis.teachlog(coachid.current, page, pagesize, countData, fields.begin, fields.end).then(ret => {
            apiResult(ret, (data) => {
                countData == 1 && setTotalCount(data.total)
                countData == 1 && setTotalFee(data.totalFee)
                countData == 1 && setSettledFee(data.settledFee)
                setDatas(data.data)
                if (data.data.length > 0) {
                    let title = coachid.current > 0 ? data.data[0].member.name : 'All members'
                    setTitle(title)
                }
                setCurrentPage(page)
            }, setError)
        })
    }

    const initPage = useRef(false)
    useEffect(() => {
        if (initPage.current) return
        initPage.current = true
        loadData(0, rowsPerPage, 1)
    }, [params])

    const handleRightDrawer = () => {
        autoCloseRightDrawer && setRightDrawer(false)
    }

    const handleChangePage = (page, rowsperpage) => {
        loadData(page, rowsperpage, 0)
    }
    const handleChangeRowsPerPage = (rowsperpage) => {
        setRowsPerPage(rowsperpage)
        setError()
        loadData(0, rowsperpage, 0)
    }
    const handleEdit = (id, idx) => {
        const order = datas[idx]
        // setRightDrawer(<EditTransaction transaction={order} onClose={(ret) => {
        //     setRightDrawer();
        //     if (ret) {
        //         const tt = [...datas]
        //         tt[idx] = { ...tt[idx], ...ret }
        //         if (ret.order_date && tt[idx].order) {
        //             tt[idx].order.order_date = ret.order_date
        //         }
        //         if (ret.peoples && tt[idx].order) {
        //             tt[idx].order.peoples = ret.peoples
        //         }
        //         setDatas(tt)
        //     }
        // }} />)
    }
    const handleChange = (key, v) => {
        setFields({ ...fields, [key]: v })
    }
    const handleGo = () => {
        loadData(0, rowsPerPage, 1)
    }
    const TableHeader = [
        { name: 'id', showName: 'No.' },
        {
            name: 'coach_id', showName: 'Teacher', func: (v, idx, row) => {
                return <Box title={row.isteacher == 1?"Teacher":"Assistant"} sx={{cursor:'pointer',color:row.isteacher == 1?LOGO_TEXT_COLOR:LOGO_ICON_COLOR}} onClick={()=>{
                    if(coachid.current == row.coach_id) return
                    coachid.current = row.coach_id
                    loadData(0,rowsPerPage,1)
                    navigate('/coach/lessonlog/'+coachid.current)
                }}>{row.coach.name}</Box>
            }
        },
        
        { name: 'lesson_date', showName: 'Date' },
        {
            name: 'class_id', showName: 'Class', func: (v, idx, row) => {
                return row.class_id>0 && <Box sx={{ color: LOGO_TEXT_COLOR, cursor: 'pointer' }} onClick={() => {                    
                    navigate('/class/class/' + row.class_id)
                }}>{row.class?.name}</Box>
            }
        },
        {
            name: 'member_id', showName: 'Member', func: (v, idx, row) => {
                return row.member_id>0 && <Box sx={{ color: LOGO_TEXT_COLOR, cursor: 'pointer' }} onClick={() => {                    
                    navigate('/member/lessonlog/' + row.member_id)
                }}>{row.member?.name}</Box>
            }
        },
        {
            name: 'course_id', showName: 'Course', func: (v, idx, row) => {
                return row.course_id > 0 && <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{
                    navigate('/lesson/course/'+row.course_id)
                }}>{row.course?.name}</Box>
            }
        },
        {
            name: 'lesson_id', showName: 'Lesson', func: (v, idx, row) => {
                return row.lesson_id > 0 ?(row.lessons && row.lessons.length > 0 ? (row.lesson_id+":"+row.lessons[0].name) : ("Lesson "+row.lesson_id)):''
            }
        },   
        showFee && { name:'fee',showName:'Fee',func:(v,idx,row)=>{
            return row.status == 1?v:''
        }},
        showFee && { name: 'need_settle', showName: 'Settle',func:(v,idx,row)=>{
            return row.status == 1 && row.settle_status == 0 &&  <IconButton title="Need to settle?" size="small" onClick={()=>{
                apis.teachlogUpdate({id:row.id,need_settle:v==1?0:1}).then(ret=>{
                    apiResult(ret,data=>{
                        let cc = [...datas]
                        cc[idx].need_settle = cc[idx].need_settle==1?0:1
                        setDatas(cc)
                    },setError)
                })
            }}>{v==1?<CreditCard color="info" />:<CreditCardOff />}</IconButton>
        } },
        showFee && { name:'settle_status',showName:'Settlement',func:(v,idx,row)=>{
            return (row.status == 1 || row.settle_status == 1) && row.need_settle == 1 && <IconButton size="small" onClick={()=>{
                apis.teachlogUpdate({id:row.id,settle_status:v==1?0:1}).then(ret=>{
                    apiResult(ret,data=>{
                        let cc = [...datas]
                        cc[idx].settle_status = cc[idx].settle_status==1?0:1
                        setDatas(cc)
                    },setError)
                })
            }}>{row.settle_status==1?<PriceCheck  color="info"/>:<Payment />}</IconButton>
        }},
        
        { name: 'status', showName: 'Checkin',func:(v,idx,row)=>{
            return <IconButton size="small" onClick={()=>{
                row.settle_status == 0 ? apis.teachlogUpdate({id:row.id,status:v==1?0:1}).then(ret=>{
                    apiResult(ret,data=>{
                        let cc = [...datas]
                        cc[idx].status = cc[idx].status==1?0:1
                        setDatas(cc)
                    },setError)
                }):setHintMsg("Already settled,can't change the status")
            }}>{v==1?<Mood color="info" />:<MoodBad />}</IconButton>
        } },
    ]

    return (
        <>
            
            <Paper sx={{ marginBottom: 2, p: 1 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <Typography variant="h6" sx={{ ml: 1 }} component="div" >Lesson records of {title}</Typography>
                    
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    {/* <Button onClick={()=>{navigate('/accounting/datas/'+params.id+'/'+(params.kid?params.kid:0))}}>switch</Button>               */}
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" sx={{ mt: "8px" }} type="date" name="begin" onChange={(e) => handleChange('begin', e.target.value)} value={fields.begin} id="begin" label="begin"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" type="date" sx={{ mt: "8px" }} name="end" onChange={(e) => handleChange('end', e.target.value)} value={fields.end} id="end" label="end"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <Button variant='contained' onClick={handleGo}>Go</Button>
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    <IconButton title="Coach fee" onClick={()=>setShowFee(!showFee)}><Payment />{showFee?totalFee:'...'}</IconButton>
                    {showFee && <IconButton title="Settled fee" sx={{ml:2}}><PriceCheck />{showFee?settledFee:'...'}</IconButton>}
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    {coachid.current > 0 && <IconButton onClick={() => {
                        coachid.current = 0
                        loadData(0, rowsPerPage, 1)
                        navigate('/coach/lessonlog/0')
                    }} variant="default"><People /></IconButton>}
                </Toolbar>
            </Paper>
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any datas</Alert>}
            {totalCount > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={totalCount}
                headers={TableHeader}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </>

    )
}
export default TeachRecords