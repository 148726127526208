import { useState } from 'react';
import { Button, TextField, Grid, Box, Typography, Alert, } from '@mui/material';
import { formToJson } from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { SingleSelector } from '../../../Component/MuiEx';
const EditHoliday = (props) => {
    const { data, onSubmit, onClose } = props
    const [fieldErrors, setFieldErrors] = useState()
    const [error, setError] = useState()
    const [holidayType,setHolidayType] = useState(0)
    // const session = getUserSession(apis)

    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.id = data ? data.id : 0
        postData.type = holidayType
        onSubmit && onSubmit(postData, setError, setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Holiday</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField type="date" defaultValue={data?.begin} name="begin" fullWidth id="begin" label="Begin date" InputLabelProps={{shrink:true}}
                                error={fieldErrors && fieldErrors.begin ? true : false}
                                helperText={fieldErrors && fieldErrors.begin ? fieldErrors.begin : ''} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="date" defaultValue={data?.end}  name="end" fullWidth id="end" label="End date" InputLabelProps={{shrink:true}}
                                error={fieldErrors && fieldErrors.end ? true : false}
                                helperText={fieldErrors && fieldErrors.end ? fieldErrors.end : ''} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text" defaultValue={data?.name}  name="name" fullWidth id="name" label="Name"
                                error={fieldErrors && fieldErrors.name ? true : false}
                                helperText={fieldErrors && fieldErrors.name ? fieldErrors.name : ''} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number" defaultValue={(data?.days) || 0}  name="days" fullWidth id="days" label="Days" InputLabelProps={{shrink:true}}
                                error={fieldErrors && fieldErrors.end ? true : false}
                                helperText={fieldErrors && fieldErrors.end ? fieldErrors.end : ''} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <SingleSelector sx={{mt:-1}} defaultValue={holidayType} items={["National","Public school","Long Weekend"]} values={[0,1,2]} name="type" label="Holiday type" onChange={(name,value)=>{setHolidayType(value)}} />
                        </Grid>
                    </Grid>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default EditHoliday