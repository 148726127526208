import { useEffect, useRef, useState } from 'react';
import { Button, TextField, Grid, Box, Typography, Alert, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { formToJson, getUserSession } from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { CourseLoader, CourseSelect } from '../../fragement/CourseSelector';
import apis from '../../../api';
import { CoachLoader, CoachSelect } from '../../fragement/CoachSelector';
import { CourseCode } from '../../../Utils/Dict';

const EditClass = (props) => {
    const { data, onSubmit, onClose } = props
    const [fieldErrors, setFieldErrors] = useState()
    const [error, setError] = useState()
    const [wday, setWDay] = useState()
    const [courses, setCourses] = useState()
    const [coaches, setCoaches] = useState()
    const [lessons, setLessons] = useState(data?.lessons)
    const [fields, setFields] = useState({ course_id: data?.course_id, teacher_id: data?.teacher_id, assistant_id: data?.assistant_id })
    getUserSession(apis)
    const inputRef = useRef()
    const setTextInputRef = (element) => {
        inputRef.current = element;
    };
    useEffect(() => {
        CourseLoader((datas) => {
            setCourses(datas)
        }, apis)
        CoachLoader((datas) => {
            setCoaches(datas)
        }, apis)
    }, [])
    const handleWeekdayChange = (name, value) => {
        setWDay(value)
    }
    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();

        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.id = data ? data.id : 0
        postData.wday = wday ? wday : data?.wday
        if (fields.course_id > 0) {
            postData.course_id = fields.course_id
        } else {
            setError("You should select a course")
            return
        }
        if (fields.teacher_id > 0) {
            postData.teacher_id = fields.teacher_id
        } else {
            setError("You should select a teacher")
            return
        }
        if (fields.assistant_id > 0) {
            postData.assistant_id = fields.assistant_id
        } else {
            postData.assistant_id = 0
        }
        if (fields.classtype) {
            postData.classtype = fields.classtype
        } else {
            postData.classtype = data?.classtype
        }
        onSubmit && onSubmit(postData, setError, setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Class</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField defaultValue={data?.name} name="name" required fullWidth id="name" label="Class name"
                                error={fieldErrors && fieldErrors.name ? true : false}
                                helperText={fieldErrors && fieldErrors.name ? fieldErrors.name : ''}
                                autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {courses && <CourseSelect courses={courses} defaultValue={data?.course_id} name={"course"} onChange={(k, v) => { setFields({ ...fields, course_id: v != null ? v.id : 0 }); setLessons(v != null ? v.lessonhours : 0) }} />}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {coaches && <CoachSelect label="Select a teacher" coaches={coaches} defaultValue={data?.teacher_id} name={"teacher"} onChange={(k, v) => { setFields({ ...fields, teacher_id: v != null ? v.id : 0 }) }} />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {coaches && <CoachSelect label="Select a teacher assistant" coaches={coaches} defaultValue={data?.assistant_id} onChange={(k, v) => { setFields({ ...fields, assistant_id: v != null ? v.id : 0 }) }} />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="teacher_fee" type="number" fullWidth id="teacher_fee" label="Teacher fee" defaultValue={data?.teacher_fee} InputLabelProps={{ shrink: true }} required
                                error={fieldErrors && fieldErrors.teacher_fee ? true : false}
                                helperText={fieldErrors && fieldErrors.teacher_fee ? fieldErrors.teacher_fee : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="assistant_fee" type="number" fullWidth id="assistant_fee" label="Assistant fee" defaultValue={data?.assistant_fee} InputLabelProps={{ shrink: true }} required
                                error={fieldErrors && fieldErrors.assistant_fee ? true : false}
                                helperText={fieldErrors && fieldErrors.assistant_fee ? fieldErrors.assistant_fee : ''}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <TextField name="lessons" fullWidth id="lessons" label="Course lessons" defaultValue={lessons} InputLabelProps={{ shrink: true }}
                                error={fieldErrors && fieldErrors.lessons ? true : false}
                                helperText={fieldErrors && fieldErrors.lessons ? fieldErrors.lessons : ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField name="current_lesson" fullWidth id="current_lesson" label="Current lesson" defaultValue={data ? data.current_lesson : 0}
                                error={fieldErrors && fieldErrors.current_lesson ? true : false}
                                helperText={fieldErrors && fieldErrors.current_lesson ? fieldErrors.current_lesson : ''}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <TextField name="fee" fullWidth id="fee" label="Course fee" defaultValue={data?.fee}
                                error={fieldErrors && fieldErrors.fee ? true : false}
                                helperText={fieldErrors && fieldErrors.fee ? fieldErrors.fee : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                margin="normal"
                                sx={{ mt: "0" }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                name="begindate"
                                label={"Course begin date"}
                                defaultValue={data?.begindate}
                                error={fieldErrors && fieldErrors.begindate ? true : false}
                                helperText={fieldErrors && fieldErrors.begindate ? fieldErrors.begindate : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="atschool" fullWidth id="atschool" label="Location" defaultValue={data?.atschool}
                                error={fieldErrors && fieldErrors.atschool ? true : false}
                                helperText={fieldErrors && fieldErrors.atschool ? fieldErrors.atschool : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="provider" fullWidth id="provider" label="Provider" defaultValue={data?.provider}
                                error={fieldErrors && fieldErrors.provider ? true : false}
                                helperText={fieldErrors && fieldErrors.provider ? fieldErrors.provider : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="divided_rate" fullWidth id="divided_rate" label="Divided rate" defaultValue={data && data.divided_rate > 0 ? data.divided_rate : 1.00}
                                error={fieldErrors && fieldErrors.divided_rate ? true : false}
                                helperText={fieldErrors && fieldErrors.divided_rate ? fieldErrors.divided_rate : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-autowidth-label">{"Select type"}</InputLabel>
                                <Select labelId="demo-simple-select-autowidth-label"
                                    id="classtype" onChange={(e) => { setFields({ ...fields, classtype: e.target.value }); }}
                                    defaultValue={data && data.classtype} label={"Select Type"}>
                                    {CourseCode.map((p, idx) => {
                                        return <MenuItem key={idx} value={p}>{p}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="term" fullWidth id="term" label="Term" defaultValue={data && data.term}
                                error={fieldErrors && fieldErrors.term ? true : false}
                                helperText={fieldErrors && fieldErrors.term ? fieldErrors.term : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete disablePortal id="week-day-selector"
                                options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                                autoComplete
                                autoHighlight
                                autoSelect
                                onChange={(_event, newitem) => { handleWeekdayChange('customer', newitem) }}
                                defaultValue={data && data.wday}
                                renderInput={(params) => <TextField {...params} label="Week Day" inputRef={setTextInputRef} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField name="times" fullWidth id="times" label="Time" defaultValue={data && data.times}
                                error={fieldErrors && fieldErrors.times ? true : false}
                                helperText={fieldErrors && fieldErrors.times ? fieldErrors.times : ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField name="earlybird_fee" fullWidth id="earlybird_fee" label="Early bird fee" defaultValue={data?.earlybird_fee}
                                error={fieldErrors && fieldErrors.earlybird_fee ? true : false}
                                helperText={fieldErrors && fieldErrors.earlybird_fee ? fieldErrors.earlybird_fee : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                margin="normal"
                                sx={{ mt: "0" }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                name="earlybird_date"
                                label={"Early bird date"}
                                defaultValue={data?.earlybird_date}
                                error={fieldErrors && fieldErrors.earlybird_date ? true : false}
                                helperText={fieldErrors && fieldErrors.earlybird_date ? fieldErrors.earlybird_date : ''}
                            />
                        </Grid>*/}

                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.note} name="note" fullWidth id="note" label="Note"
                                error={fieldErrors && fieldErrors.note ? true : false}
                                helperText={fieldErrors && fieldErrors.note ? fieldErrors.note : ''}
                            />
                        </Grid>
                    </Grid>
                    {error && <Alert severity="error" onClose={() => { setError() }}>{error}</Alert>}
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} > Submit </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button type="button" fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
export default EditClass