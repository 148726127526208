// import Editor from "@monaco-editor/react";
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { Box } from "@mui/material"
import { useCallback} from "react";
import { EditorView } from "@codemirror/view";


function CodeEditor(props) {
    const onChange = useCallback((value, viewUpdate) => {
        props.onChange && props.onChange(value)
    }, []);    
    const Theme = EditorView.theme({        
        "&": {
            fontSize: props.size,
            background:'#fff',
          }}
      );
    return <Box sx={{ pt: 2, pb: 2,background:props.bgcolor}}>
        <CodeMirror
            value={props.value}            
            height={props.height}
            theme={Theme}
            autoFocus={true}
            extensions={[python()]}
            onChange={onChange}
        />
    </Box>
}

export default CodeEditor