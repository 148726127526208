import MyTable from "../../Component/MyTable"
import { MenuItem, InputLabel,Select,TextField,FormControl,  Alert, Paper, Toolbar, Typography, Button,  Snackbar,    } from "@mui/material";
import { useEffect, useState } from "react";
import { apiResult,  formatDate,  getCurrentMonth01, getDateDiffMonth, getUserSession,  } from "../../Utils/Common";
import apis from "../../api";
import {RightDrawer, } from "../../Component/MuiEx";
import TransactionOptButton from "./fragement/TransactionOptButton";
import {  useParams } from "react-router";
import { LOGO_TEXT_COLOR } from "../../App/config";

const BalanceLog = () => {    
    const [hintMsg, setHintMsg] = useState()    
    const [transactions, setTransactions] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [title, setTitle] = useState()
    const [error, setError] = useState()
    const [ fields,setFields] = useState({begin:getDateDiffMonth(6),end:formatDate(new Date()),action:'all'})
    const [rightDrawer, setRightDrawer] = useState()   //open or close right drawer
    const [autoCloseRightDrawer, setAutoCloseRightDrawer] = useState(true)
    const params = useParams()
    getUserSession(apis)
    // const navigate = useNavigate()
        
    const handleHintClose = () => {
        setHintMsg()
    }
    const loadTransactions = (page, pagesize, countData) => {
        //setLoading(true)
        setError()
        const userid = params.userid ? params.userid : 0        
        const memberid = params.memberid ? params.memberid : 0        
        apis.balancelog(userid,memberid,page, pagesize, countData,fields.begin,fields.end,fields.action).then(ret => {
            //setLoading(false)
            apiResult(ret, (data) => {
                countData == 1 && setTotalCount(data.total)
                setTransactions(data.data)
                if (data.data.length > 0) {
                    let title = userid>0?data.data[0].user_profile.name:'all family'
                    title = memberid>0?data.data[0].member.name:title
                    setTitle(title)
                }
                setCurrentPage(page)
            }, setError)
        })
    }
    
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadTransactions(0, rowsPerPage, 1)    
    }, [params])


    const handleRightDrawer = () => {
        autoCloseRightDrawer && setRightDrawer(false)
    }

    const handleChangePage = (page, rowsperpage) => {
        loadTransactions(page, rowsperpage, 0)
    }
    const handleChangeRowsPerPage = (rowsperpage) => {
        setRowsPerPage(rowsperpage)
        setError()
        loadTransactions(0, rowsperpage, 0)
    }
    const handleRefund = (id, idx) => {
        setError()
        //setRightDrawer(<Refund item={transactions[idx]} onClose={(ret) => { ret && loadTransactions(currentPage, rowsPerPage, 0, orderField.name, orderField.order); setRightDrawer() }} />)
    }
    const handleEdit = (id, idx) => {
        const order = transactions[idx]
        // setRightDrawer(<EditTransaction transaction={order} onClose={(ret) => {
        //     setRightDrawer();
        //     if (ret) {
        //         const tt = [...transactions]
        //         tt[idx] = { ...tt[idx], ...ret }
        //         if (ret.order_date && tt[idx].order) {
        //             tt[idx].order.order_date = ret.order_date
        //         }
        //         if (ret.peoples && tt[idx].order) {
        //             tt[idx].order.peoples = ret.peoples
        //         }
        //         setTransactions(tt)
        //     }
        // }} />)
    }
    const handleChange = (key,v) =>{        
        setFields({...fields,[key]:v})
    }
    const handleGo = ()=>{
        loadTransactions(0,rowsPerPage,1)
    }
    const TransactionTableHeader = [
        { name: 'id', showName: 'No.' },
        {
            name: 'sdate', showName: 'Date', func: (v, idx, row) => {
                return row.sdate?row.sdate:row.create_time.substring(0,10)
            }
        },
        {
            name: 'user_id', showName: 'Family', func: (v, idx, row) => {
                return <div style={{ cursor: 'pointer',color:LOGO_TEXT_COLOR }} onClick={() => {
                    window.open('/member/balancelog/' + row.user_id + '/0', '_balance_log')
                }}>{row.user_profile?.name}</div>
            }
        },
        {
            name: 'member_id', showName: 'Member', func: (v, idx, row) => {
                if (row.member_id > 0) {                    
                    return <div style={{ cursor: 'pointer',color:LOGO_TEXT_COLOR }} onClick={() => {
                        window.open('/member/balancelog/' + row.user_id + '/'+row.member_id, '_balance_log')
                    }}>{row.member?.name}</div>    
                }
            }
        },
        { name: 'balance_type', showName: 'Balance type',},        
        { name: 'action', showName: 'Action' },        
        { name: 'amount', showName: 'Amount' },        
        { name: 'pre_balance', showName: 'Pre-Balance',func:(v,idx,row)=>{
            if(row.action === 'cashcharge') {
                return ""
            }else {
                return v
            }
        }},
        {name:'target_id',showName:"target",func:(v,idx,row)=>{
            let url = "/class/class/"+String(v)
            return row.target && <Typography sx={{fontSize:"14px"}}><a href={url} target="_class">{row.target}-{v}</a></Typography>;
        }},
        { name: 'note', showName: 'Note' },        
    ]

    return (
        <>            
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2,p:1 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <Typography variant="h6" sx={{ml:1}} component="div" >Transactions of {title}</Typography>
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    {/* <Button onClick={()=>{navigate('/accounting/transactions/'+params.id+'/'+(params.kid?params.kid:0))}}>switch</Button>               */}
                    <FormControl sx={{ mr: 2 ,width:'180px'}}>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select action</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { handleChange('action', e.target.value) }}
                            defaultValue={'all'}
                            label="Select action" >
                            {['all','charge','recharge','cashcharge'].map((p, idx) => {
                                return <MenuItem key={idx} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>                    
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" sx={{ mt: "8px" }} type="date" name="begin" onChange={(e) => handleChange('begin', e.target.value)} value={fields.begin} id="begin" label="begin"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" type="date" sx={{ mt: "8px" }} name="end" onChange={(e) => handleChange('end', e.target.value)} value={fields.end} id="end" label="end"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <Button variant='contained' onClick={handleGo}>Go</Button>
                </Toolbar>
            </Paper>
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any transactions</Alert>}
            {totalCount > 0 && <MyTable
                height={650}
                rows={transactions}
                totalRow={totalCount}
                headers={TransactionTableHeader}                
                OpentionComponent={(id, idx) => {
                    return <TransactionOptButton
                        //onTransactionHistory={(id, idx) => { params.id && handleTransactionHistory(id, idx) }}
                        //onKidTransactionHistory={(id, idx) => { params.id && handleKidTransactionHistory(id, idx) }}
                        onEdit={(id, idx) => handleEdit(id, idx)}
                        onRefund={(id, idx) => handleRefund(id, idx)}
                        //onCancel={(id, idx) => handleCancelTransaction(id, idx)}                        
                        id={id} index={idx} />
                }}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </>

    )
}
export default BalanceLog