import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import apis from "../../api"
import { apiResult, getUserSession, sessionGet, sessionSet } from "../../Utils/Common"

const MemberAutoComplete = (props) => {
    const { defaultValue, onChange, name, label } = props
    const [members, setMembers] = useState(props.members)
    const inputRef = useRef()
    const [timeStamp, setTimeStamp] = useState()
    useEffect(() => {
        inputRef.current?.focus()
        if (inputRef.current) {
            inputRef.current.focus()
        }

    }, [timeStamp])
    getUserSession(apis)
    useEffect(() => {
        !members && MemberLoader((data) => {               
            setMembers(data)      
        }, apis)
    }, [])
    const setTextInputRef = (element) => {
        inputRef.current = element;
    };
    const getDefaultItem = (id)=>{
        for (let i = 0; i < members.length; i++) {                
            if (members[i].id == id) {                    
                return members[i]
            }
        }
        return null
    }
    return <FormControl fullWidth sx={props.sx}>
        {members && <Autocomplete disablePortal id="member-selector"
            options={members}
            autoComplete
            autoHighlight
            autoSelect
            defaultValue={defaultValue && getDefaultItem(defaultValue)}
            getOptionLabel={(option) => option.id+" | "+option.name + " @ "+option.user_profile.name}
            onChange={(_event, newitem) => { onChange &&  onChange(name, newitem) }}
            renderInput={(params) => <TextField {...params} label={label ? label : "Member"} inputRef={setTextInputRef} />}
        />}
    </FormControl>
}

const MemberSelect = (props) => {
    const { defaultValue,onChange,members,name,label} = props
    const [_members,setMembers] = useState(members)
    getUserSession(apis)
    useEffect(()=>{
        if(!_members) {
            MemberLoader((data)=>{setMembers(data)},apis)
        }
    },[])
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label ?label:"Select Member"}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id="product"
            onChange={(e) => { 
                let id = e.target.value; 
                let choice = false
                if(id>0) {
                    for(let i=0;i<_members.length;i++) {
                        if(id == _members[i].id) {
                            onChange && onChange(name,_members[i])
                            choice = true
                            break
                        }
                    }
                }
                if(!choice) {
                    onChange && onChange(name,{id:0})
                }
            }}
            defaultValue={defaultValue}
            label={label ?label:"Select course"}
        >
            <MenuItem value={0}>Nothing</MenuItem>
            {_members && _members.map((p, idx) => {
                return <MenuItem key={idx} value={p.id}>{p.id+'-'+p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}
const MemberLoader = (callback, apis) => {
    let member_list = sessionGet('member_list')
    if (member_list === null) {
        apis.loadMembers(0, 1000, 0).then(ret => {
            apiResult(ret, data => {
                sessionSet('member_list', data.data)
                callback(data.data)
            })
        })
    } else {
        callback(member_list)
    }
}
const getMember = (members,id)=>{
    for(let i=0;i<members.length;i++) {
        if(members[i].id == id) {
            return members[i]
        }
    }
    return undefined
}
export { MemberLoader, MemberAutoComplete,MemberSelect,getMember }