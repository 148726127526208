import MyTable from "../../../Component/MyTable"
import { Alert, Paper, Toolbar, Typography,  Box, ButtonGroup, Stack, Switch, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { apiResult, getUserSession, } from "../../../Utils/Common";
import { RightDrawer } from "../../../Component/MuiEx";
import { useNavigate, useParams } from "react-router";
import IconButton from "@mui/material/IconButton/IconButton";
import { CurrencyExchange, Edit, NoAccounts, Payment, Person, PersonAdd, PersonOutline, Receipt, Schedule } from "@mui/icons-material";
import EditClassMember from "./EditClassMember";
import apis from "../../../api";
import Recharge from "./Recharge";
import Refund from "./Refund";
import ClassMemberRecords from "./ClassMemberRecords";
import MemberEditForm from "../../member/fragement/MemberEditForm";
import UserEditForm from "../../member/fragement/UserEditForm";
import { LOGO_TEXT_COLOR } from "../../../App/config";
import EditSchedule from "./EditSchedule";

const ClassMemberList = (props) => {
    const { classid,classobj,all,onChange,onLoaded } = props    
    const [rightDrawer, setRightDrawer] = useState()
    const [allSchedule,setAllSchedule] = useState(false)
    const [datas, setDatas] = useState([])    
    const [error, setError] = useState()
    const [autoCloseRightDraw, setAutoCloseRightDraw] = useState(true)    
    getUserSession(apis)

    const loadDatas = () => {        
        apis.classmembers(classid, all).then(ret => {
            apiResult(ret, data => {
                setDatas(data)
            }, setError)
        })
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadDatas()
    }, [])

    const handleAfterEdit = (idx, data, err, errs) => {
        idx == -1 ? apis.classmemberPost(data).then(ret => {
            apiResult(ret, (id) => {
                setAutoCloseRightDraw(true)
                setRightDrawer(false)
                loadDatas()
                classobj.students += 1
                onChange && onChange(classobj)
            }, err, errs)
        }) : apis.classmemberPut(data).then(ret => {
            apiResult(ret, (retdata) => {
                setAutoCloseRightDraw(true)
                setRightDrawer(false)
                loadDatas()                
            }, err, errs)
        })
    }
    const handleRightDrawer = () => {
        if (autoCloseRightDraw)
            setRightDrawer(false)
    }

    const handleEdit = (idx, item) => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditClassMember classobj={classobj} data={item} onSubmit={(data, err, errs) => { handleAfterEdit(idx, data, err, errs) }} onClose={() => { setRightDrawer(false) }} />)
    }
    const handleAdd = () => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditClassMember classobj={classobj} data={{ id: 0, class_id: classid }} onSubmit={(data, err, errs) => { handleAfterEdit(-1, data, err, errs) }} onClose={() => { setRightDrawer(false) }} />)
    }
    const handleDelete = (idx, item) => {
        apis.classmemberDelete(item.id).then(ret => {
            apiResult(ret, data => {
                let cc = [...datas]
                cc[idx].status = 0
                setDatas(cc)
                classobj.students -= 1
                onChange && onChange(classobj)
            }, setError)
        })
    }
    const handleEnable = (idx, item) => {
        apis.classmemberPut({ id: item.id,class_id:item.class_id, status: 1 }).then(ret => {
            apiResult(ret, data => {
                let cc = [...datas]
                cc[idx].status = 1
                setDatas(cc)
                classobj.students += 1
                onChange && onChange(classobj)
            }, setError)
        })
    }
    const handleAfterPay = ()=>{
        loadDatas()
        setRightDrawer()
        setAutoCloseRightDraw(true)
    }
    const handlePay = (idx,item)=>{
        setAutoCloseRightDraw(false)
        setRightDrawer(<Recharge memberId={item.member_id} unit={item.lesson_price} classId={item.class_id} onClose={()=>setRightDrawer()} onSubmit={handleAfterPay}/>)
    }
    const handleRefund = (idx,item)=>{
        setAutoCloseRightDraw(false)
        setRightDrawer(<Refund item={item} onClose={()=>setRightDrawer()} onSubmit={handleAfterPay}/>)
    }
    const handleEditMember = (id)=>{
        setRightDrawer(<MemberEditForm id={id} onClose={()=>setRightDrawer()} title="Edit Member"/>)
    }
    const handleEditUser = (idx)=>{
        if(datas[idx].member.parent) {
            setRightDrawer(<UserEditForm user={datas[idx].member.parent} onClose={()=>setRightDrawer()} title="Edit Parent"/>)
        }else {
            apis.loadParent(datas[idx].member.user_id).then(ret=>{
                apiResult(ret,data=>{
                    datas[idx].member.parent = data.user_profile   
                    data.user_profile.id =  data.id
                    data.user_profile.area_id = data.area_id   
                    console.log(data.user_profile)
                    setRightDrawer(<UserEditForm user={data.user_profile} onClose={()=>setRightDrawer()} title="Edit Parent"/>)
                },setError)
            })
        }    
    }
    const handleTransaction = (user_id,id)=>{
        window.open("/member/balancelog/"+user_id+"/"+id,'transaction_log')
    }
    const handleAfterScheduleEdit = (idx, schedule) => {        
        setAutoCloseRightDraw(true)
        setRightDrawer(false)
        window.location.reload()
    }
    const handleEditSchedule = () => {
        setAutoCloseRightDraw(false)
        setRightDrawer(<EditSchedule schedule={{ id: 0 }} classid={classid} onAfterEdit={(data) => { handleAfterScheduleEdit() }} onClose={() => { setRightDrawer(false) }} />)
    }
    const TableHeader = [
        { name: 'id', showName: 'ID' },
        {
            name: 'name', showName: 'Name', func: (v, idx, item) => {
                return <Stack direction="row">
                        <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{handleEditMember(item.member.id)}}>{item.member.name}</Box>
                        <IconButton sx={{mt:-0.5}} title={"Parent Information"} size="small" onClick={()=>{handleEditUser(idx)}}><PersonOutline /></IconButton>
                        <IconButton sx={{mt:-0.5}} title={"Transaction information"} onClick={()=>{handleTransaction(item.member.user_id,item.member.id)}} size="small"><Receipt fontSize="10px"/> </IconButton>
                    </Stack>
            }
        },
        { name: 'join_date', showName: 'Join date',func:(v,idx,item)=>{
            if(item.exit_date && item.exit_date !='' && item.exit_date != null) {
                return <Stack direction={'column'}>
                    <span>{v}</span>
                    <span style={{color:'red'}}>{item.exit_date}</span>
                </Stack>
            }else {
                return v
            }
        }},
        { name: 'fee', showName: 'Fee',func:(v,idx,item)=>{
            return Number(item.paid).toFixed(2)+"/"+Number(v).toFixed(2)
        } },        
        { name: 'lessons', showName: 'Lessons',func:(v,idx,item)=>{
            return item.remained_lessons+ (item.from_lesson>1?("/"+item.from_lesson):"")+"/"+v
        } },
        { name: 'lesson_price', showName: 'Unit price' },
        { name: 'free_lessons', showName: 'Free lessons',func:(v,idx,item)=>{
            return v>0?(item.used_free_lessons+"/"+v):0
        }},        
        { name: 'allow_absent', showName: 'Allow absent times',func:(v,idx,item)=>{
            return v>0?(item.absent_times+"/"+v):(v?v:0)
        }},        
        {
            name: 'option', showName: 'Options', func: (v, idx, item) => {
                return <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
                    <IconButton size="small" onClick={() => item.status != 0 ? handleDelete(idx, item) : handleEnable(idx, item)}>{item.status == 0 ? <NoAccounts fontSize="10px"/>:<Person fontSize="10px"/>}</IconButton>                    
                    <IconButton size="small" onClick={() => handleEdit(idx, item)}><Edit fontSize="10px"/></IconButton>                    
                    {Number(item.fee) > Number(item.paid) && <IconButton size="small" onClick={() => handlePay(idx, item)} title="Recharge"><Payment fontSize="10px"/></IconButton>}
                    {Number(item.remained_lessons) > 0 && <IconButton size="small" onClick={() => handleRefund(idx, item)}><CurrencyExchange fontSize="10px"/></IconButton>}
                </ButtonGroup>
            }
        }
    ]
    return (
        <>            
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2 }}>
                <Toolbar>
                    <Typography sx={{ ml: 2 }} variant="h6" component='h6'>Members</Typography>
                    <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" ></Typography>
                    <FormControlLabel control={<Switch onChange={(e)=>setAllSchedule(e.target.checked)} />} label="All schedule" /> 
                    <IconButton onClick={handleEditSchedule} title="Add Schedule"><Schedule /></IconButton>
                    <IconButton onClick={handleAdd} title="Add Member"><PersonAdd /></IconButton>
                </Toolbar>
            </Paper>

            {datas.length == 0 && <Alert severity={"info"}>Ops! There is not any lesson</Alert>}
            {datas.length > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={datas.length}
                headers={TableHeader}
                checkbox={false}
                rowsPerPage={datas.length}
                showPageination={false}
            />}
            {datas.length>0 && <ClassMemberRecords listAll={allSchedule} members={datas} classid={classid} onLoaded={onLoaded}/>}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
        </>

    )
}
export default ClassMemberList