import { IconButton,Button, Toolbar, Typography,  Divider,  Paper,  } from "@mui/material"
import { SearchBar } from "../../../Component/MuiEx";
import {Add} from '@mui/icons-material'
const FamilyManagerBar = (props) => {    
    let {onSearch,onAdd} = props
    return (        
            <Paper sx={{ marginBottom: 2 }}>
                <Toolbar style={{ paddingLeft: 2 }}>                    
                    {onSearch && <>
                        <SearchBar placeholder="name | email | phone" onSearch={onSearch} /></>}
                    <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" > </Typography>
                    <IconButton onClick={onAdd}><Add /></IconButton>
                </Toolbar>
            </Paper>
    )
}
export {FamilyManagerBar}