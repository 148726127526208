import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Dialog, Box, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { MemberLevel, SingleSelector } from '../../../Component/MuiEx';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { CoachSelect } from '../../fragement/CoachSelector';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ScheduleStatusDialog = (props) => {      
    const { schedule, onClose, onSubmit, } = props
    const [optionitems,setOptionItems] = useState()
    const [optionvalues,setOptionValues] = useState()
    const [field, setField] = useState({id:schedule.id})
    useEffect(()=>{        
        setOptionItems(['Delete schedule','Cancel schedule','Holiday','Postpone','Modify schedule'])
        setOptionValues([0,3,5,2,-1])
    },[schedule])
    const time = (Math.floor(Number(schedule.begintime) / 60) < 10 ? ('0' + Math.floor(Number(schedule.begintime) / 60)) : Math.floor(Number(schedule.begintime) / 60)) + ':' + (Number(schedule.begintime) % 60 < 10 ? ('0' + Number(schedule.begintime) % 60) : Number(schedule.begintime) % 60) 
    const timeToMinutes = (v) => {
        let t = v.split(':')
        if (t.length == 2) {
            return Number(t[0]) * 60 + Number(t[1])
        } else {
            return 0
        }
    }
    return <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
            Change Schedule -- {schedule.pname}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-autowidth-label1">Select option</InputLabel>
                        <Select labelId="demo-simple-select-autowidth-label1"
                            onChange={(e) => { 
                                let ff = {...field}
                                ff.status = e.target.value
                                if(ff.status == 4) {
                                    ff.changeall = 2
                                }
                                setField(ff) 
                            }}
                            label="Select option" >                            
                            {optionitems && optionitems.map((p, idx) => {
                                return <MenuItem key={idx} value={optionvalues[idx]}>{p}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
            {[-1].indexOf(field.status) >= 0 && <Box >                                  
                
                <TextField fullWidth label="Date" margin={"normal"} defaultValue={schedule.sdate} type='date' onChange={(e) => { setField({ ...field, sdate: e.target.value }) }} />                                
                <TextField fullWidth label="Begin Time" margin={"normal"} defaultValue={time} type='time' onChange={(e) => { setField({ ...field, begintime: timeToMinutes(e.target.value) }) }} />                
                <TextField fullWidth label="Duration" margin={"normal"} defaultValue={schedule.duration} type='number' onChange={(e) => { setField({ ...field, duration: Number(e.target.value) }) }} />
                {[1,2].indexOf(field.changeall)<0 && field.status == -1 && <TextField fullWidth label="Lesson No." margin={"normal"} defaultValue={schedule.lesson} type='number' onChange={(e) => { setField({ ...field, lesson: Number(e.target.value) }) }} />}
                
            </Box>}
            {field.status == -1 && <Box>
                <CoachSelect name="teacher_id" sx={{mt:1}} onChange={(name,item)=>{setField({...field,[name]:item.id})}} label={"Modify teacher"} defaultValue={schedule.teacher_id} />
                <CoachSelect name="assistant_id" sx={{mt:2}} onChange={(name,item)=>{setField({...field,[name]:item.id})}} label={"Modify assistant"} defaultValue={schedule.assistant_id} />
            </Box>}
            <TextField fullWidth label="Note" margin={"normal"} defaultValue={schedule.note} type='text' onChange={(e) => { setField({ ...field,note:e.target.value}) }} />
            {[-1,0].indexOf(field.status)>= 0 && schedule.class_id>0 && <SingleSelector label={'Apply to:'} defaultValue={0} items={['Current schedule', 'Class after this schedule', 'Whole class schedule']} values={[0, 1, 2]} name="deleteOption" onChange={(n, v) => { setField({...field,changeall:v}) }}></SingleSelector>}
        </DialogContent>
        <DialogActions>
            {onClose && <Button autoFocus onClick={onClose}>
                Cancel
            </Button>}
            {onSubmit && <Button autoFocus onClick={() => onSubmit(field)}>
                Ok
            </Button>}
        </DialogActions>
    </BootstrapDialog>
}
const EditScheduleDialog = (props) => {
    const { schedule, onClose, onSubmit, } = props
    const [field, setField] = useState({id:schedule.id,})
    const time = (Math.floor(Number(schedule.begintime) / 60) < 10 ? ('0' + Math.floor(Number(schedule.begintime) / 60)) : Math.floor(Number(schedule.begintime) / 60)) + ':' + (Number(schedule.begintime) % 60 < 10 ? ('0' + Number(schedule.begintime) % 60) : Number(schedule.begintime) % 60) 
    const timeToMinutes = (v) => {
        let t = v.split(':')
        if (t.length == 2) {
            return Number(t[0]) * 60 + Number(t[1])
        } else {
            return 0
        }
    }
    const handleSubmit = ()=>{
        
        field.option >= 0 && onSubmit && onSubmit(field)
    }
    return <BootstrapDialog        
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
    >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
            Edit Schedule
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Box sx={{ p: 2 }}>                  
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField label="Begin Time" margin={"normal"} defaultValue={time} type='time' onChange={(e) => { setField({ ...field, begintime: timeToMinutes(e.target.value) }) }} />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField label="Duration" margin={"normal"} defaultValue={schedule.duration} type='number' onChange={(e) => { setField({ ...field, duration: Number(e.target.value) }) }} />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <SingleSelector defaultValue={0} label="Apply to" items={['Current date', 'After this date', 'All']} values={[0, 1, 2]} name="editOption" onChange={(n, v) => { setField({...field,option:v}) }}></SingleSelector>
                </FormControl>
            </Box>

        </DialogContent>
        <DialogActions>
            {onClose && <Button autoFocus onClick={onClose}>
                Cancel
            </Button>}
            {onSubmit && <Button autoFocus onClick={() => handleSubmit()}>
                Ok
            </Button>}
        </DialogActions>
    </BootstrapDialog>
}

export { ScheduleStatusDialog, EditScheduleDialog }