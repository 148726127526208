import { List, ListItem, Grid, Box, Typography, Alert } from "@mui/material"
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { useRef } from "react";
import { useEffect, useState } from "react"
import apis from "../../../api";
import { apiResult, deepCopy, getUserSession } from "../../../Utils/Common";
import OptionBar from "./OptionBar";

import PageEditor from "./PageEditor";
import SlideContentItem from "./SlideContentItem";

export default function EditPageContent(props) {
    const { data, itemindex, onChange, onClose } = props
    const [error, setError] = useState()
    const [content, setContent] = useState()
    const [leftSize, setLeftSize] = useState(9)
    const changed = useRef(false)
    getUserSession(apis)
    useEffect(() => {
        if (data.content && typeof data.content == 'string') {
            if (data.content.length > 0) {
                data.content = JSON.parse(data.content)
            } else {
                data.content = []
            }
        } else if (data.content == null || !data.content) {
            data.content = []
        }
        if (data.content.length === 0) {
            data.content.push({ type: 'text', content: '', id: 'f' + new Date().getTime() })
        }
        setContent(data.content)
        // window.onbeforeunload = (e) => {

        //     if(changed.current && !window.confirm("Are you sure to quit without saved your changed?")) {
        //         e.preventDefault()
        //         e.stopPropagation()
        //         console.log(e)
        //     }
        // }
        // window.history.pushState(null,null,window.document.URL)
        // window.onpopstate = (e) => {            
        //     if (changed.current && !window.confirm("Are you sure to quit without saved your changed?")) {                
        //         window.history.pushState(null, window.document.title, window.document.URL);                
        //     }
        // }
    }, [])
    const [index, setIndex] = useState(0)
    const [subindex, setSubIndex] = useState(-1)
    const [model, setModel] = useState(2)
    window.onkeydown = (e) => {
        if (e.keyCode === 112) {
            setModel((model + 1) % 3)
        }
    }
    const handleAddItem = (option) => {
        let dd = [...content]
        if (option === 1) {
            dd.splice(index + 1, 0, { type: 'text', content: '', size: "24px", color: '#000', bold: false, id: 'f' + new Date().getTime() })
            setContent(dd)
            setIndex(index + 1)
            setSubIndex(-1)
        } else {
            dd.splice(index, 0, { type: 'text', content: '', size: "24px", color: '#000', bold: false, id: 'f' + new Date().getTime() })
            setContent(dd)
            setSubIndex(-1)
        }
        changed.current = true
    }
    const handleClone = () => {
        let dd = [...content]
        let i = 0;
        let obj = deepCopy(content[index])
        obj.id = 'f' + new Date().getTime()+String(i++)
        if (typeof obj.content === 'object') {
            for (let item of obj.content) {
                item.id = 'f' + new Date().getTime()+String(i++)
            }
        }
        dd.splice(index + 1, 0, obj)
        setContent(dd)
        setIndex(index + 1)
        setSubIndex(-1)
    }
    const handleMove = (d) => {
        if (index >= 0) {
            let dd = [...content]
            let t = dd[index]
            if (d == -1 && index > 0) {
                dd[index] = dd[index - 1]
                dd[index - 1] = t
                setIndex(index - 1)
            } else if (d == 1 && index < dd.length - 1) {
                dd[index] = dd[index + 1]
                dd[index + 1] = t
                setIndex(index + 1)
            }
            setContent(dd)
        }
    }
    const handleAddChildItem = (option) => {
        let i = 0
        let dd = [...content]
        if (typeof dd[index].content === 'string') {
            let newcc = { type: 'content', content: [dd[index]], id: new Date().getTime()+String(i++) }
            dd[index] = newcc
        }
        if (subindex < 0) {
            dd[index].content.push({ type: 'text', content: '', size: "24px", color: '#000', bold: false, id: 'f' + new Date().getTime()+String(i++) })
            setSubIndex(dd[index].content.length - 1)
        } else {
            if (option === 1) {
                dd[index].content.splice(subindex + 1, 0, { type: 'text', content: '', id: 'f' + new Date().getTime()+String(i++) })
                setSubIndex(subindex + 1)
            } else {
                dd[index].content.splice(subindex, 0, { type: 'text', content: '', id: 'f' + new Date().getTime()+String(i++) })
            }
        }

        setContent(dd)
        changed.current = true
    }
    const handleSubClone = () => {
        let dd = [...content]
        if (subindex >= 0) {
            let obj = deepCopy(dd[index].content[subindex])
            obj.id = 'f' + new Date().getTime()
            dd[index].content.splice(subindex + 1, 0, obj)
            setContent(dd)
            setSubIndex(subindex + 1)
        }
    }
    const handleSubMove = (d) => {
        if (subindex >= 0) {
            let dd = [...content]
            let t = dd[index].content[subindex]
            if (d == -1 && subindex > 0) {
                dd[index].content[subindex] = dd[index].content[subindex - 1]
                dd[index].content[subindex - 1] = t
                setSubIndex(subindex - 1)
            } else if (d == 1 && subindex < dd[index].content.length - 1) {
                dd[index].content[subindex] = dd[index].content[subindex + 1]
                dd[index].content[subindex + 1] = t
                setSubIndex(subindex + 1)
            }
            setContent(dd)
        }
    }
    const handleOnChange = (idx, subidx, key, value) => {
        let dd = [...content]
        if (subidx >= 0) {
            dd[idx].content[subidx][key] = value
            if (!dd[idx].content[subidx].id) {
                dd[idx].content[subidx].id = 'f' + new Date().getTime()
            }
        } else {
            dd[index][key] = value
            if (!dd[idx].id) {
                dd[idx].id = 'f' + new Date().getTime()
            }
        }
        setContent(dd)
        setError()
        changed.current = true
    }
    const handleItemClick = (idx, subidx, item) => {
        if (subidx < 0) {
            setIndex(idx)
            setSubIndex(-1)
        } else {
            setIndex(idx)
            setSubIndex(subidx)
        }
    }
    const handleRemoveItem = () => {
        if (!window.confirm("Are you sure to remove the item?")) {
            return
        }
        let dd = [...content]
        if (subindex >= 0) {
            dd[index].content.splice(subindex, 1)
            if (dd[index].content.length > 0) {
                setSubIndex(subindex < dd[index].content.length - 1 ? subindex + 1 : dd[index].content.length - 1)
            } else {
                setSubIndex(-1)
            }
        } else {
            dd.splice(index, 1)
        }
        setContent(dd)
        changed.current = true
    }
    const handleSave = () => {
        data.content = JSON.stringify(content)
        apis.lessonpagePut(data).then(ret => {
            apiResult(ret, result => {
                onChange(data, itemindex)
                changed.current = false
            }, setError)
        })
    }
    const handleClose = () => {
        if (changed.current && !window.confirm("Quit without saveing changed?")) {
            return
        } else {
            onClose(0)
        }
    }
    const handleRight = () => {
        setLeftSize(8)
    }
    const handleLeft = () => {
        setLeftSize(leftSize > 4 ? leftSize - 2 : 4)
    }
    return <Box>
        <OptionBar pageNo={data.pageNo} title={data.title} onMove={handleMove} onSubMove={handleSubMove} onLeft={handleLeft} onClone={handleClone} onSubClone={handleSubClone} onRight={handleRight} onAddNext={() => handleAddItem(1)} onAddChildNext={() => handleAddChildItem(1)} onAddPre={() => handleAddItem(-1)} onAddChildPre={() => handleAddChildItem(-1)} onRemove={handleRemoveItem} onSave={handleSave} onClose={handleClose} />
        {error && <Alert severity='error' onClose={() => setError()} >{error} </Alert>}
        <Grid container spacing={1}>
            <Grid item xs={leftSize}>
                {/* <Toolbar style={{ padding: "4px", paddingLeft: '20px', minHeight: '32px', background: '#ccca' }}>
                <Typography variant="h6" >Page {data.pageNo} : {data.title} </Typography>
            </Toolbar> */}
                <Box sx={{ maxHeight: '93vh', overflow: "auto" }}>
                    <Grid container sx={{ p: 2 }} spacing={2}>
                        {content && content.map((childitem, idx) => {
                            return (model === 0 && index >= idx) || (model === 1 && index === idx) || model === 2 ? <Grid item xs={childitem.grid ? Number(childitem.grid) : 12}>
                                <SlideContentItem sx={{ mt: 1, mb: 1 }} item={childitem} idx={idx} subindex={subindex} selected={index === idx} model={model} onClick={handleItemClick} />
                            </Grid> : <></>
                        })}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12 - leftSize} >
                {content && content.length > 0 && <Box sx={{ p:1,height: '93vh', display: 'flex', overflow:"auto", flexDirection: 'column', alignItems: 'center', }}>                    
                        <PageEditor content={subindex >= 0 ? content[index].content[subindex] : content[index]} onChange={handleOnChange} index={index} subindex={subindex} />                    
                </Box>}
            </Grid>
        </Grid>        
    </Box>
    //     return <Grid container spacing={2}>
    //     <Grid item xs={leftSize}>
    //         <Toolbar style={{ padding: "4px", paddingLeft: '20px', minHeight: '32px', background: '#ccca' }}>
    //             <Typography variant="h6" >Page {data.pageNo} : {data.title} </Typography>
    //         </Toolbar>
    //         <List sx={{ maxHeight: '90vh', overflow: "auto" }}>
    //             {content && content.map((item, idx) => {
    //                 return <ListItem key={idx} sx={{ pt: 0.5, pb: 0.5, display: (model === 0 && index >= idx) || (model === 1 && index === idx) || model === 2 ? 'block' : 'none' }}>
    //                     <SlideContentItem sx={{ mt: 1, mb: 1 }} item={item} idx={idx} subindex={subindex} selected={index === idx} model={model} onClick={handleItemClick} />
    //                 </ListItem>
    //             })}
    //         </List>
    //     </Grid>
    //     <Grid item xs={12 - leftSize} >
    //         <OptionBar onLeft={handleLeft} onRight={handleRight} onAddNext={() => handleAddItem(1)} onAddChildNext={() => handleAddChildItem(1)} onAddPre={() => handleAddItem(-1)} onAddChildPre={() => handleAddChildItem(-1)} onRemove={handleRemoveItem} onSave={handleSave} onClose={handleClose} />
    //         {content && content.length > 0 && <Box sx={{ marginTop: 6, p: 2, height: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
    //             <Box component="div">
    //                 <PageEditor content={subindex >= 0 ? content[index].content[subindex] : content[index]} onChange={handleOnChange} index={index} subindex={subindex} />
    //                 {error && <Alert severity='error' onClose={() => setError()} >{error} </Alert>}
    //             </Box>
    //         </Box>}
    //     </Grid>
    // </Grid>
}