import { useEffect, useRef, useState } from "react";
import apis from "../../api";
import { apiResult,  formatDate,  formatDate1,  getCurrentMonth01, getUserSession,  } from "../../Utils/Common";
const Reports = ()=>{
    const [reports,setReports] = useState()
    const [fields,setFields] = useState({begin:formatDate1(new Date().getFullYear(),1,1),end:formatDate(new Date())})
    const [error,setError] = useState()
    const initPage = useRef(false)
    getUserSession(apis)
    const loadDatas = () =>{
        apis.reports(fields.begin,fields.end).then(ret=>{
            apiResult(ret,data=>{
                setReports(data)
            },setError)
        })
    }
    useEffect(()=>{
        if(initPage.current) return
        initPage.current = true
        loadDatas()
    },[])
    return <div>
        
    </div>
}
export default Reports