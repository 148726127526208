import {  Toolbar, Typography,   Divider, IconButton,  } from "@mui/material"
import {AddCard, ArrowBack, ArrowDownward, ArrowForward,  ArrowUpward,  CopyAll,  Logout, PlaylistAdd, Remove, Save} from '@mui/icons-material';
const OptionBar = (props) => {
    const {pageNo,title,onMove,onSubMove,onClone,onSubClone,onRight,onLeft,onAddPre,onAddNext,onAddChildPre,onAddChildNext,onSave,onRemove,onClose} = props
    return <Toolbar style={{ padding: "4px", paddingLeft: '20px', minHeight: '32px', background: '#ccca',width:'100%' }}>        
            <Typography variant="h6"  >Page {pageNo} : {title} </Typography>
            <Typography sx={{ flex: '1 1 30%' }} variant="h6" component="div" > </Typography>
            {/* {onLeft && <IconButton   onClick={onLeft}>
                <ArrowBack />                
            </IconButton>}
            {onRight && <><Divider variant="middle" orientation="vertical" flexItem></Divider>
            <IconButton   onClick={onRight}>                
                <ArrowForward />                
            </IconButton></>}
            <Typography sx={{ flex: '1 1 10%' }} variant="h6" component="div" > </Typography> */}
            {onAddPre && <IconButton   title={"Add Content"} onClick={onAddPre}>                
                <AddCard />                
            </IconButton>}
            {onAddNext && <IconButton   title={"Add Content"} onClick={onAddNext}>                
                <AddCard />                
            </IconButton>}
            {onClone && <IconButton   title={"Clone"} onClick={onClone}>                
                <CopyAll />                
            </IconButton>}
            {onMove && <IconButton   title={"Up"} onClick={()=>onMove(-1)}>                
                <ArrowUpward />                
            </IconButton>}
            {onMove && <IconButton   title={"Down"} onClick={()=>onMove(1)}>                
                <ArrowDownward />                
            </IconButton>}
            <Divider variant="middle" orientation="vertical" flexItem></Divider>
            {onAddChildPre && <IconButton   title={"Add sub-content"} onClick={onAddChildPre}>                
                <PlaylistAdd />                
            </IconButton>}            
            {onAddChildNext && <IconButton   title={"Add sub-content"} onClick={onAddChildNext}>                
                <PlaylistAdd />                
            </IconButton>} 
            {onSubClone && <IconButton   title={"Clone"} onClick={onSubClone}>                
                <CopyAll />                
            </IconButton>}   
            {onSubMove && <IconButton   title={"Up"} onClick={()=>onSubMove(-1)}>                
                <ArrowUpward />                
            </IconButton>}
            {onSubMove && <IconButton   title={"Down"} onClick={()=>onSubMove(1)}>                
                <ArrowDownward />                
            </IconButton>}        
            {onRemove && <><Divider variant="middle" orientation="vertical" flexItem></Divider>
            <IconButton   title={"Remove the content"} onClick={onRemove}>                
                <Remove />                
            </IconButton></>}     
            {onSave && <IconButton   title={"Save"} onClick={onSave}>                
                <Save />                
            </IconButton>}      
            {onClose && <><Divider variant="middle" orientation="vertical" flexItem></Divider>
            <IconButton   title={"Close"} onClick={onClose}>                
                <Logout />                
            </IconButton></>}
    </Toolbar>
}
export default OptionBar