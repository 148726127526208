import { useEffect, useState } from 'react';
import { Button, TextField,  Grid, Box, Typography, Alert, } from '@mui/material';
import {  formatDate, formatDate1, formToJson} from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { MemberAutoComplete } from '../../fragement/MemberAutoComplete';

const EditClassMember = (props) => {
    const {data,onSubmit,onClose,classobj} = props
    const [fieldErrors, setFieldErrors] = useState()    
    const [member,setMember] = useState()
    const [error, setError] = useState()
    // const session = getUserSession(apis)    
    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        if(member) {
            data.member_id = member.id
        }
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.id = data ? data.id:0
        postData.class_id = data && data.class_id ?data.class_id:0
        postData.member_id = data?data.member_id:0
        if(postData.exit_date == '' || postData.exit_date == null) {            
            delete postData.exit_date
        } 
        //postData.passwd && postData.passwd.length>0 && (postData.passwd = hex_md5(postData.passwd))
        onSubmit && onSubmit(postData,setError,setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Course</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <MemberAutoComplete defaultValue={data?.member_id} onChange={(name,value)=>{setMember(value)}}/>
                        </Grid>    
                        <Grid item xs={12} sm={6}>
                            <TextField type="text" label="Course fee" name="fee" id="fee" fullWidth defaultValue={data && data.fee?data.fee:classobj?.fee} required 
                                error={fieldErrors && fieldErrors.fee ? true : false}
                                helperText={fieldErrors && fieldErrors.fee ? fieldErrors.fee : ''}/>
                        </Grid>    
                        <Grid item xs={12} sm={6}>
                            <TextField disabled type="text" label="Paid fee" name="paid" id="paid" fullWidth defaultValue={data && data.paid?data.paid:0}
                                error={fieldErrors && fieldErrors.paid ? true : false}
                                helperText={fieldErrors && fieldErrors.paid ? fieldErrors.paid : ''}/>
                        </Grid>    
                        <Grid item xs={12} sm={6}>
                            <TextField type="date" label="Join date" name="join_date" id="join_date" fullWidth defaultValue={data && data.join_date?data.join_date:formatDate(new Date())} 
                                error={fieldErrors && fieldErrors.join_date ? true : false}
                                helperText={fieldErrors && fieldErrors.join_date ? fieldErrors.join_date : ''}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="date" label="Exit date" name="exit_date" id="exit_date" fullWidth defaultValue={data && data.exit_date} 
                                InputLabelProps={{shrink:true}}
                                error={fieldErrors && fieldErrors.exit_date ? true : false}
                                helperText={fieldErrors && fieldErrors.exit_date ? fieldErrors.exit_date : ''}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number" label="From lesson" name="from_lesson" id="from_lesson" fullWidth defaultValue={data && data.from_lesson?data.from_lesson:1}
                                error={fieldErrors && fieldErrors.from_lesson ? true : false}
                                helperText={fieldErrors && fieldErrors.from_lesson ? fieldErrors.from_lesson : ''}/>
                        </Grid>          
                        <Grid item xs={12} sm={6}>
                            <TextField type="number" label="Lessons (Not include free lessons)" name="lessons" id="lessons" fullWidth defaultValue={data && data.lessons?data.lessons:classobj?.lessons}
                                error={fieldErrors && fieldErrors.lessons ? true : false}
                                helperText={fieldErrors && fieldErrors.lessons ? fieldErrors.lessons : ''}/>
                        </Grid>    
                        <Grid item xs={12} sm={6}>                        
                            <TextField type="text" label="Unit Price" name="lesson_price" id="lesson_price" fullWidth defaultValue={data && data.lesson_price>0?data.lesson_price:(classobj?(Number(classobj.fee)/Number(classobj.lessons)):0)}
                                error={fieldErrors && fieldErrors.lesson_price ? true : false}
                                helperText={fieldErrors && fieldErrors.lesson_price ? fieldErrors.lesson_price : ''}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>                        
                            <TextField type="number" label="Free lessons" name="free_lessons" id="free_lessons" fullWidth defaultValue={data && data.free_lessons>0?data.free_lessons:0}
                                error={fieldErrors && fieldErrors.free_lessons ? true : false}
                                helperText={fieldErrors && fieldErrors.free_lessons ? fieldErrors.free_lessons : ''}/>
                        </Grid>      
                        <Grid item xs={12} sm={6}>                        
                            <TextField type="number" label="Allow absent times" name="allow_absent" id="allow_absent" fullWidth defaultValue={data && data.allow_absent?data.allow_absent:0}
                                error={fieldErrors && fieldErrors.allow_absent ? true : false}
                                helperText={fieldErrors && fieldErrors.allow_absent ? fieldErrors.allow_absent : ''}/>
                        </Grid>      
                    </Grid>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { onClose && onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default EditClassMember