import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../../api"
import { apiResult, getUserSession, sessionGet, sessionSet } from "../../Utils/Common"


const CoachSelect = (props) => {
    const { defaultValue,onChange,coaches,name,label } = props
    const [_coaches,setCoaches] = useState(coaches)
    getUserSession(apis)
    useEffect(()=>{
        if(!_coaches) {
            CoachLoader((data)=>{setCoaches(data)},apis)
        }
    },[])
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id="product"
            onChange={(e) => { 
                let id = e.target.value; 
                let choice = false
                if(id>0) {
                    for(let i=0;i<_coaches.length;i++) {
                        if(id == _coaches[i].id) {
                            onChange && onChange(name,_coaches[i])
                            choice = true
                            break
                        }
                    }
                }
                if(!choice) {
                    onChange && onChange(name,{id:0})
                }
            }}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={0}>Nothing</MenuItem>
            {_coaches && _coaches.map((p, idx) => {                       
                return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}
const CoachLoader = (callback, apis) => {
    let coaches_list = sessionGet('coaches_list')    
    if (coaches_list === null) {
        apis.loadCoaches().then(ret => {
            apiResult(ret, data => {
                sessionSet('coaches_list',data)
                callback(data)
            })
        })
    } else {
        callback(coaches_list)
    }
}
const getCoach = (coaches,id)=>{
    for(let i=0;i<coaches.length;i++) {
        if(coaches[i].id == id) {
            return coaches[i]
        }
    }
    return undefined
}
export {CoachLoader,CoachSelect,getCoach}