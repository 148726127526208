import { useState } from 'react';
import { Button, TextField,  Grid, Box, Typography, Alert, } from '@mui/material';
// import { formToJson} from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { formToJson } from '../../Utils/Common';

const FormEdit = (props) => {
    const {data,onSubmit,items,title} = props
    const [fieldErrors, setFieldErrors] = useState()    
    const [error, setError] = useState()
    // const session = getUserSession(apis)

    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        //postData.passwd && postData.passwd.length>0 && (postData.passwd = hex_md5(postData.passwd))
        onSubmit && onSubmit({...data,...postData},setError,setFieldErrors)
    };

    // { name: 'level', showName: 'Level', },
    //     { name: 'wdate', showName: 'Week day', },
    //     { name: 'bdate', showName: 'Begin', },
    //     { name: 'edate', showName: 'End', },
    //     { name: 'times', showName: 'Time', },
    //     { name: 'lessons', showName: 'Lesson', },
    //     { name: 'price', showName: 'Price', },
    //     { name: 'opencount', showName: 'Available', },
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">{title}</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        {items?.map((item,idx)=>{
                            return <Grid key={idx} item xs={12} sm={12}>
                            <TextField defaultValue={data?data[item.name]:null} name={item.name} required={item.required} fullWidth id={item.name} label={item.showName}
                                error={fieldErrors && fieldErrors[item.name] ? true : false}
                                helperText={fieldErrors && fieldErrors[item.name] ? fieldErrors[item.name] : ''}
                                autoFocus />
                        </Grid>
                        })}                        
                    </Grid>

                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default FormEdit