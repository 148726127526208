import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../../api"
import { apiResult, getUserSession, sessionGet, sessionSet } from "../../Utils/Common"


const ClassSelect = (props) => {
    const { defaultValue,onChange,classes,name,label } = props
    const [_classes,setClasses] = useState(classes)
    getUserSession(apis)
    useEffect(()=>{
        if(!_classes) {
            ClassLoader((data)=>{setClasses(data)},apis)
        }
    },[])
    return <FormControl fullWidth  sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id="product"
            onChange={(e) => { 
                let id = e.target.value; 
                let choice = false                
                if(id> 0)  {
                    for(let i=0;i<_classes.length;i++) {
                        if(id == _classes[i].id) {
                            onChange && onChange(name,_classes[i])
                            choice = true
                            break
                        }
                    }
                }
                if(!choice) {
                    onChange && onChange(name,{id:0})
                }
            }}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={0}>Nothing</MenuItem>
            {_classes && _classes.map((p, idx) => {                
                return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}
const ClassLoader = (callback, apis) => {
    let classes_list = sessionGet('classes_list')
    if (classes_list === null) {
        apis.classes().then(ret => {
            apiResult(ret, data => {
                sessionSet('classes_list',data)
                callback(data)
            })
        })
    } else {
        callback(classes_list)
    }
}
const getClass = (classes,id)=>{
    for(let i=0;i<classes.length;i++) {
        if(classes[i].id == id) {
            return classes[i]
        }
    }
    return undefined
}
export {ClassLoader,ClassSelect,getClass}