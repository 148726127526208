import { LOGO_ICON_COLOR, LOGO_TEXT_COLOR } from "../../../App/config";
import { SingleSelector } from "../../../Component/MuiEx";
import Uploader from "../../../Component/Uploader";
import CodeEditor from "./CodeEditor";
import { Grid, Box, TextField, Alert, Divider, Paper } from "@mui/material"
import { useState } from "react";

const PageEditor = (props) => {
    const types = ['Title', 'Text', 'Code', 'Table', 'Picture', 'video', 'Radio', 'Option', 'Input', 'CodeInput', 'Upload']
    const values = ['h', 'text', 'code', 'table', 'picture', 'video', 'radio', 'option', 'input', 'codeinput', 'upload']
    const subtypes = ['Title', 'Text', 'Code', 'Table', 'Picture', 'Radio', 'Option', 'Input', 'CodeInput', 'Upload']
    const subvalues = ['h', 'text', 'code', 'table', 'picture', 'radio', 'option', 'input', 'codeinput', 'upload']
    const colors = [                  
                    ['#fff', "#000000", 'Default'],                       
                    ['#fff', LOGO_TEXT_COLOR, 'Text'], 
                    ['#fff', '#ff0000', 'Red'],                     
                    ['#fff', '#00ff00', 'Green'], 
                    ['#fff', '#00008B', 'Blue'], 
                    ['#fff', '#D2691E', 'Knowledge'], 
                    ['#fff', '#5F9EA0', 'Tips'], 
                    ['#fff', '#6495ED', 'Practice'], 
                    ['#fff', '#2F4F4F', 'Options'], 
                    ['#fff', '#4B0082', 'Question'], 
                    [LOGO_ICON_COLOR, '#fff', 'Orange'], 
                    [LOGO_TEXT_COLOR, '#fff', 'Deep Blue'], 
                    ['#8884','#000','Gray'],
                    ['#FAEBD7', '#000', 'AntiqueWhite'],
                    ['#FAEBD7', LOGO_ICON_COLOR, 'AntiqueWhite'],
                    ['#D2691E','#fff','Chocolate'],
                    ['#7FFF00','#000','Green'],
                    ['#FFB6C1','#000','LightPink'],
                ]
    const bgColors = []
    for (let c of colors) {
        bgColors.push(<Paper sx={{ bgcolor: c[0], color: c[1], p: 1, fontSize: '10px' }}>{c[2]}</Paper>)
    }
    const { content, index, subindex, onChange } = props
    const [error, setError] = useState()
    return content && <Grid container spacing={1.5}>
        <Grid item xs={12} sm={12}>
            <SingleSelector
                items={subindex < 0 ? types : subtypes} values={subindex < 0 ? values : subvalues}
                defaultValue={content.type ? content.type : ''}
                name="type"
                onChange={(name, value) => onChange(index, subindex, name, value)}
            />            
        </Grid>
        <Grid item xs={12} sm={12}>
            {content.type && ['h', 'text'].indexOf(content.type) >= 0 && <TextField multiline={content.type === 'text'} rows={content.type === 'text' ? 6 : 1} name="content" onChange={(e) => { onChange(index, subindex, 'content', e.target.value) }} fullWidth id="content" label="Content" value={content.content} />}
            {content.type === 'picture' && <Box>
                <TextField margin="normal" type="text" onChange={(e) => onChange(index, subindex, 'content', e.target.value)} value={content.content} fullWidth label="Image url" />
                <Uploader onUpload={(filename) => {
                    onChange(index, subindex, 'content', filename)
                }} onFailed={setError} />
            </Box>}
            {content.type === 'video' && <Box>
                <TextField margin="normal" multiline rows={3} type="text" onChange={(e) => onChange(index, subindex, 'content', e.target.value)} value={content.content} fullWidth label="video url" />
            </Box>}
            {(content.type === 'code' || content.type === 'table') && <Box>
                <CodeEditor value={content.content} index={index} subindex={subindex} height={"300px"} onChange={(v) => { onChange(index, subindex, 'content', v) }} />
            </Box>}
            {(content.type === 'radio' || content.type === 'option') && <Box>
                <TextField margin="normal" type="text" onChange={(e) => onChange(index, subindex, 'content', e.target.value)} value={content.content} placeholder="comma separated items" fullWidth label="Items" />
            </Box>}
        </Grid>
        {['radio', 'option', 'codeinput', 'input'].indexOf(content.type) >= 0 && <Grid item xs={12}>
            <TextField margin="normal" type="text" onChange={(e) => onChange(index, subindex, 'label', e.target.value)} value={content.label} placeholder="Label" fullWidth label="Label" />
        </Grid>}

        {content.type === 'h' && <Grid item xs={12} sm={12}>
            {/* <TextField name="size" onChange={(e) => { onChange('size', e.target.value) }} fullWidth label="H(1~6)" defaultValue={content.size} /> */}
            <SingleSelector items={["H1", "H2", "H3", "H4", "H5", "H6"]} values={[1, 2, 3, 4, 5, 6]} defaultValue={content.size?content.size:2}  name="size" onChange={(name, value) => onChange(index, subindex, name, value)} />
        </Grid>}
        <Grid item xs={12} sm={12}>
            {/* <TextField name="size" onChange={(e) => { onChange('size', e.target.value) }} fullWidth label="Size (px)" defaultValue={content.size} /> */}
            <SingleSelector items={['72',"60","48", "36", "24", "18"]} values={['72px','60px','48px', '36px', '24px', '18px']} defaultValue={content.size} name="size" onChange={(name, value) => onChange(index, subindex, name, value)} />
        </Grid>
        {['h', 'text'].indexOf(content.type) >= 0 && <Grid item xs={12} sm={12}>
            <SingleSelector items={['Bold', 'Normal']} values={[true, false]} defaultValue={content.bold ? content.bold : false} name="bold" onChange={(name, value) => onChange(index, subindex, name, value)} />
        </Grid>}
        {/* {['h', 'text'].indexOf(content.type) >= 0 && <Grid item xs={12} sm={12}>
            <SingleSelector
                items={[
                    <span style={{ textDecoration: 'underline red 2px' }}>red</span>,
                    <span style={{ textDecoration: 'underline orange 2px' }}>orange</span>,
                    <span style={{ textDecoration: 'line-through red 2px' }}>red</span>,
                    <span style={{ textDecoration: 'line-through red 2px' }}>orange</span>,
                    <span style={{ textDecoration: '' }}>default</span>,
                ]}
                values={['underline red 2px', 'underline orange 2px', 'line-through red 2px', 'line-through orange 2px', '']} defaultValue={content.decoration} name="decoration"
                onChange={(name, value) => onChange(index, subindex, name, value)} />
        </Grid>} */}
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'mt', e.target.value)} value={content.mt ? content.mt : 0} fullWidth label="Margin Top" />
        </Grid>
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'mb', e.target.value)} value={content.mb ? content.mb : 0} fullWidth label="Margin Bottom" />
        </Grid>
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'p', e.target.value)} value={content.p ? content.p : 1} fullWidth label="Padding" />
        </Grid>
        {(content.type === 'picture' || content.type === 'video') && <Grid item xs={6}>
            <TextField name="width" onChange={(e) => { onChange(index, subindex, 'width', e.target.value) }} fullWidth id="width" label="Width" value={content.width} />
        </Grid>}
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'height', e.target.value)} value={content.height} fullWidth label="Height" />
        </Grid>
        
        <Grid item xs={12}>
            <SingleSelector
                items={bgColors}
                values={colors} defaultValue={colors[0]} name="styles"
                onChange={(name, value) => {
                    onChange(index, subindex, 'bgcolor', value[0])
                    onChange(index, subindex, 'color', value[1])
                }} />
        </Grid>
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'bgcolor', e.target.value)} value={content.bgcolor ? content.bgcolor : '#ffffff'} fullWidth label="Background Color" />
        </Grid>
        <Grid item xs={6}>
            <TextField InputLabelProps={{ shrink: true }} type="text" onChange={(e) => onChange(index, subindex, 'color', e.target.value)} value={content.color ? content.color : '#000000'} fullWidth label="Text Color" />
        </Grid>
        
        <Grid item xs={12}>
            <SingleSelector items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} defaultValue={content.grid ? content.grid : 12} onChange={(name, value) => onChange(index, subindex, name, value)} name="grid" label="Grid" />
        </Grid>
        {['radio', 'option', 'codeinput', 'input', 'upload'].indexOf(content.type) >= 0 && <Grid item xs={6}>
            <SingleSelector items={["Yes", "No"]} values={[true, false]} onChange={(n, v) => onChange(index, subindex, 'reply', v)} defaultValue={content.reply === false ? false : true} label="Need to reply?" />
        </Grid>}
        {['radio', 'option', 'codeinput', 'input', 'upload'].indexOf(content.type) >= 0 && <Grid item xs={12}>
            <SingleSelector items={['member', 'teacher', 'both']} values={['member', 'teacher', 'both']} onChange={(n, v) => onChange(index, subindex, 'belongto', v)} defaultValue={content.belongto ? content.belongto : 'both'} label="Who can edit?" />
        </Grid>}
        <Grid item xs={12}>
            <SingleSelector items={['Teacher', 'Both']} values={[1, 0]} onChange={(n, v) => onChange(index, subindex, 'showto', v)} defaultValue={content.showto ? content.showto : 0} label="Who can view?" />
        </Grid>
        {error && <Alert severity='error' onClose={() => setError()} >{error} </Alert>}
    </Grid>
}
export default PageEditor