import { Box, TextField, Paper, Typography, Grid } from "@mui/material"
import { nord, vs2015, CopyBlock } from "react-code-blocks";
import { MultiSelector, SingleSelector } from "../../../Component/MuiEx";
import SimpleTable from "../../../Component/SimpleTable";

import CodeEditor from "./CodeEditor";
import UploaderItem from "./UploaderItem";
const SlideContentItem = (props) => {
    const { item, idx, subindex, selected, model, onClick, onChange } = props
    const itemMinHeight = 100
    return <Paper sx={{ p: 2, width: "100%", border: selected && (model !== 1) ? '2px solid gray' : '1px dotted gray', boxShadow: '0 0 0 0 !important', minHeight: selected ? itemMinHeight : 0, filter: selected && "drop-shadow(3px 3px 3px gray)" }} onClick={(e) => { e.preventDefault(); onClick && onClick(idx, -1, item) }}>
        {item.type === 'content' ? <Grid container sx={{bgcolor:item.bgcolor,color:item.color,marginTop:item.mt?Number(item.mt):0,marginBottom:item.mb?Number(item.mb):0,p:item.p?Number(item.p):1}}>
            {item.content && item.content.map((subitem, subidx) => {
                return <Grid item xs={subitem.grid ? subitem.grid : 12}>
                    <Box key={subidx} onClick={(e) => { e.stopPropagation(); onClick && onClick(idx, subidx, item) }} sx={{ minHeight: "30px", border: selected && subindex === subidx ? '1px solid' : '0px' }}>
                        <BlockItem1 item={subitem} idx={subidx} selected={selected && subindex == subidx} onChange={onChange} />
                    </Box>
                </Grid>
            })}
        </Grid> : <BlockItem1 item={item} idx={idx} selected={selected} onChange={onChange} />
        }
    </Paper>
}

const BlockItem1 = (props) => {
    const { item, selected, idx, onChange } = props
    let mt = item.mt ? Number(item.mt) : 0
    let mb = item.mb ? Number(item.mb) : 0    
    return <Box sx={{mt:mt,mb:mb,alignContent:'center',bgcolor:item.showto == 1?'#8884':item.bgcolor,color:item.showto == 1?'#093D65':item.color,p:item.p?item.p:1}}>
        {item.type === 'h' && item.size > 0 && <Typography variant={"h" + item.size} component={'h' + item.size} sx={{ height:item.height?.length>0?item.height:null,color: item.color, fontWeight: item.bold ? 'bold' : '', textDecoration: item.decoration }}>{item.content}</Typography>}
        {item.type === 'text' && <Typography variant={"body"} component={'p'} sx={{ color: item.color, fontSize: item.size, fontWeight: item.bold ? 'bold' : '',height:item.height?.length>0?item.height:null, textDecoration: item.decoration }}>
            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
        </Typography>}        
        {item.type === 'code' && <Box sx={{ position: 'relative', fontSize: item.size ? item.size : '20px' }}>
            <CopyBlock text={item.content} theme={ selected ? vs2015 : nord} language={'python'} showLineNumbers={false} wrapLines={true} ></CopyBlock>
        </Box>}        
        {item.type === 'picture' && <img src={item.content?.substring(0,4) === 'http'?item.content:('/api/resource/photo?file='+item.content)} style={{ width: item.width,maxHeight:item.height }} />}
        {item.type === 'video' &&  <iframe width={item.width} height={item.height} src={item.content} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
        {item.type === 'table' && <SimpleTable datas={item.content} color={item.color} size={item.size} />}
        {item.type === 'radio' && <SingleSelector size={item.size} color={item.color} label={item.label} defaultValue={item.value} name={'f' + item.id} items={item.content && item.content.length > 0 && item.content.split(',')} onChange={onChange} />}
        {item.type === 'option' && <MultiSelector size={item.size} color={item.color} label={item.label} defaultValue={item.value} name={'f' + item.id} items={item.content && item.content.length > 0 && item.content.split(',')} onChange={onChange} />}
        {item.type === 'input' && <TextField InputProps={{style:{fontSize:item.size}}} InputLabelProps={{ shrink: true }} multiline rows={item.height?item.height:6} name={'f' + item.id} onChange={(e) => { onChange && onChange('f' + item.id, e.target.value) }} fullWidth label={item.label} defaultValue={item.content} />}
        {item.type === 'codeinput' && <CodeEditor size={item.size} bgcolor={item.bgcolor} label={item.label} value={item.content} index={idx} height={item.height?item.height:'300px'} onChange={(v) => { onChange && onChange('f' + item.id, v) }} />}
        {item.type === 'upload' && <UploaderItem name={'f' + item.id} label={item.label} value={item.value} onChange={onChange} />}
    </Box>
}
// const BlockItem = (props) => {
//     const { item, selected, model, itemMinHeight, onClick, idx, subindex, onChange } = props
//     let mt = item.mt ? Number(item.mt) : 0
//     let mb = item.mb ? Number(item.mb) : 0
//     return <Paper sx={{ p: 2, width: "100%", border: selected && (model !== 1) ? '2px solid gray' : '1px dotted gray', boxShadow: '0 0 0 0 !important', minHeight: selected ? itemMinHeight : 0, filter: selected && "drop-shadow(3px 3px 3px gray)" }} onClick={(e) => { e.preventDefault(); onClick && onClick(idx, -1, item) }}>
//         {item.type === 'h' && item.size > 0 && <Typography variant={"h" + item.size} component={'h' + item.size} sx={{ mt: mt, mb: mb, color: item.color, fontWeight: item.bold ? 'bold' : '', textDecoration: item.decoration }}>{item.content}</Typography>}
//         {item.type === 'text' && <Typography variant={"body"} component={'p'} sx={{ mt: mt, mb: mb, color: item.color, fontSize: item.size, fontWeight: item.bold ? 'bold' : '', textDecoration: item.decoration }}>{item.content}</Typography>}
//         {item.type === 'content' && <Box>
//             {item.content && item.content.map((subitem, subidx) => {
//                 let mt = subitem.mt ? Number(subitem.mt) : 0
//                 let mb = subitem.mb ? Number(subitem.mb) : 0
//                 return <Box key={subidx} onClick={(e) => { e.stopPropagation(); onClick && onClick(idx, subidx, item) }} sx={{ minHeight: "30px", border: selected && subindex === subidx ? '1px solid' : '0px' }}>
//                     {subitem.type == 'h' && <Typography variant={"h" + subitem.size} component={'h' + subitem.size} sx={{ mt: mt, mb: mb, color: subitem.color, fontWeight: subitem.bold ? 'bold' : '' }}>{subitem.content}</Typography>}
//                     {subitem.type == 'text' && <Typography variant={"body"} component={'p'} sx={{ mt: mt, mb: mb, color: subitem.color, fontSize: subitem.size, fontWeight: subitem.bold ? 'bold' : '' }}>{subitem.content}</Typography>}
//                     {subitem.type == 'code' && <Box sx={{ mt: mt, mb: mb, position: 'relative', fontSize: subitem.size ? subitem.size : '20px' }}>
//                         <CopyBlock text={subitem.content} theme={selected ? vs2015 : nord} language={'python'} showLineNumbers={false} wrapLines={true} ></CopyBlock>
//                     </Box>}
//                     {subitem.type == 'picture' && <img src={subitem.content} style={{ marginBottom: mb, marginTop: mt, width: subitem.width }} />}
//                     {subitem.type == 'table' && <Box sx={{ mt: mt, mb: mb, }}><SimpleTable datas={subitem.content} color={subitem.color} size={subitem.size} /></Box>}
//                     {subitem.type === 'radio' && <SingleSelector sx={{ mt: mt, mb: mb, }} label={subitem.label} defaultValue={subitem.value} name={'f' + subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')} onChange={onChange} />}
//                     {subitem.type === 'option' && <MultiSelector sx={{ mt: mt, mb: mb, }} label={subitem.label} defaultValue={subitem.value} name={'f' + subitem.id} items={subitem.content && subitem.content.length > 0 && subitem.content.split(',')} onChange={onChange} />}
//                     {subitem.type === 'input' && <TextField sx={{ mt: mt, mb: mb, }} multiline rows={6} name={'f' + subitem.id} onChange={(e) => { onChange && onChange('f' + subitem.id, e.target.value) }} fullWidth label={subitem.label} defaultValue={subitem.value} />}
//                     {subitem.type === 'codeinput' && <CodeEditor sx={{ mt: mt, mb: mb, }} label={subitem.label} value={subitem.value} index={idx} subindex={subindex} height={"300px"} onChange={(v) => { onChange('f' + subitem.id, v) }} />}
//                     {subitem.type === 'upload' && <UploaderItem sx={{ mt: mt, mb: mb, }} name={'f' + subitem.id} label={subitem.label} value={subitem.value} onChange={onChange} />}
//                 </Box>
//             })}
//         </Box>}
//         {item.type === 'code' && <Box sx={{ mb: mb, mt: mt, position: 'relative', fontSize: item.size ? item.size : '20px' }}>
//             <CopyBlock text={item.content} theme={selected ? vs2015 : nord} language={'python'} showLineNumbers={false} wrapLines={true} ></CopyBlock>
//         </Box>}
//         {item.type === 'picture' && <Box sx={{ mt: mt, mb: mb, }}><img src={item.content} style={{ width: item.width }} /></Box>}
//         {item.type === 'table' && <Box sx={{ mt: mt, mb: mb, }}><SimpleTable datas={item.content} color={item.color} size={item.size} /></Box>}
//         {item.type === 'radio' && <SingleSelector sx={{ mt: mt, mb: mb, }} label={item.label} defaultValue={item.value} name={'f' + item.id} items={item.content && item.content.length > 0 && item.content.split(',')} onChange={onChange} />}
//         {item.type === 'option' && <MultiSelector sx={{ mt: mt, mb: mb, }} label={item.label} defaultValue={item.value} name={'f' + item.id} items={item.content && item.content.length > 0 && item.content.split(',')} onChange={onChange} />}
//         {item.type === 'input' && <TextField sx={{ mt: mt, mb: mb, }} multiline rows={6} name={'f' + item.id} onChange={(e) => { onChange && onChange('f' + item.id, e.target.value) }} fullWidth label={item.label} defaultValue={item.value} />}
//         {item.type === 'codeinput' && <CodeEditor sx={{ mt: mt, mb: mb, }} label={item.label} value={item.value} index={idx} subindex={subindex} height={"300px"} onChange={(v) => { onChange('f' + item.id, v) }} />}
//         {item.type === 'upload' && <UploaderItem sx={{ mt: mt, mb: mb, }} name={'f' + item.id} label={item.label} value={item.value} onChange={onChange} />}

//     </Paper>
// }
export default SlideContentItem