import { useState, useEffect } from "react"
import { Box, Paper, Grid, Button, IconButton, Snackbar, Alert, ButtonGroup } from '@mui/material'
import apis from "../../../api"
import { apiResult, getUserSession } from "../../../Utils/Common"
import { useRef } from "react"
import { Edit, Payment, PriceCheck, SentimentDissatisfied, SentimentSatisfied } from "@mui/icons-material"
import { LOGO_ICON_COLOR } from "../../../App/config"
import { ScheduleStatusDialog } from "./ModifyScheduleDialog"

// const getTeacher = (teachers, id) => {
//     for (let t of teachers) {
//         if (t.id == id) {
//             return t
//         }
//     }
//     return false
// }
const getMember = (members, id) => {
    for (let t of members) {
        if (t.member_id == id) {
            return t
        }
    }
    return false
}
const findChechIn = (id, checkins) => {
    for (let c of checkins) {
        if (c.coach_id == id) {
            return c
        }
    }
    return false
}
const ScheduleItem = (props) => {
    const { item, members, onTeacherCheckin, onStudentCheckin, onStudentSettle, onModifySchedule } = props
    // let assistant = getTeacher(teachers, item.assistant_id)
    // let teacher = getTeacher(teachers, item.teacher_id)
    let acr = findChechIn(item.assistant_id, item.coach_lesson_records)
    let tcr = findChechIn(item.teacher_id, item.coach_lesson_records)
    const handleTeacherCheckin = (id, tid) => {
        onTeacherCheckin && onTeacherCheckin(id, tid)
    }
    const handleStudentCheckin = (id, kid) => {
        onStudentCheckin && onStudentCheckin(id, kid)
    }
    const handleRecordSettleStatus = (id) => {
        onStudentSettle && onStudentSettle(id)
    }

    const handleModifySchedule = (id) => {
        onModifySchedule && onModifySchedule(id)
    }
    return <Paper sx={{ p: 2, mt: 2, fontSize: '14px' }}><Grid container sx={{ borderBottom: '1px solid #0001', pb: 1 }}>
        <Grid item xs={2} sx={{ color: LOGO_ICON_COLOR }}>{item.sdate}</Grid>
        <Grid item xs={2} sx={{ color: LOGO_ICON_COLOR }}>Lesson {item.lesson}</Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
            {item.Teacher && <Button onClick={() => handleTeacherCheckin(item.id, item.teacher_id)} size="small" variant={tcr && tcr.status == 1 ? "contained" : 'outlined'} >{item.Teacher.name}</Button>}
        </Grid>
        <Grid item xs={2}>
            {item.Assistant && <Button onClick={() => handleTeacherCheckin(item.id, item.assistant_id)} size="small" variant={acr && acr.status == 1 ? "contained" : 'outlined'} >{item.Assistant.name}</Button>}
        </Grid>
        <Grid item xs={2}>
            {new Date(item.sdate) > new Date() && <ButtonGroup>
                <Button size="small" variant="outlined" onClick={handleModifySchedule}><Edit fontSize="small" /></Button>
            </ButtonGroup>}
        </Grid>
    </Grid><Grid container sx={{ mt: 1 }}>
            {members.map((cm, idx) => {
                let member = getMember(item.member_lesson_records, cm.member_id)
                return <Grid container key={idx}>
                    <Grid item xs={2}>{cm.member.name}</Grid>
                    <Grid item xs={2} title="Points">${member ? member.points : 0}</Grid>
                    <Grid item xs={6}>
                        {member && <ButtonGroup size="small">
                            <Button title="Comprehension">{member?.score1}</Button>
                            <Button title="Focus">{member?.score2}</Button>
                            <Button title="Participation">{member?.score3}</Button>
                            <Button title="Discipline">{member?.score4}</Button>
                            <Button title="Homework">{member?.score5}</Button>
                            {member?.score6 > 0 && <Button title="Typing speeding">{member?.score6}</Button>}
                            {member?.comment && member?.comment.length > 0 && <Button title="Comment" onClick={() => { alert(member?.comment) }}>...</Button>}
                        </ButtonGroup>}
                    </Grid>
                    <Grid item xs={1}>{(cm.status == 1 || member) && <IconButton onClick={() => handleStudentCheckin(item.id, cm.member_id)} size="small" >
                        {member && member.checkin == 1 ? <SentimentSatisfied color="info" /> : <SentimentDissatisfied />}
                    </IconButton>}</Grid>
                    <Grid item xs={1}>{(cm.status == 1 && member) && <IconButton onClick={() => cm.status == 1 && member && handleRecordSettleStatus(member.id)} size="small" >
                        {cm.status == 1 && member && member.fee_status > 1 ? <PriceCheck color="info" /> : <Payment />}{member.fee_status > 1 ? member?.fee : ''}
                    </IconButton>}</Grid>
                </Grid>
            })}
        </Grid></Paper>
}

const ClassMemberRecords = (props) => {
    const { members, classid, onLoaded, listAll } = props
    const [error, setError] = useState()
    const [datas, setDatas] = useState()
    const [editSchedule, setEditSchedule] = useState()
    const [allSchedule, setAllSchedule] = useState(false)
    const initPage = useRef(false)
    const settledFee = useRef(0)
    const dividedFee = useRef(0)
    getUserSession(apis)
    const loadDatas = () => {
        apis.classRecords(classid).then(ret => {
            apiResult(ret, data => {
                settledFee.current = 0
                dividedFee.current = 0
                setDatas(data)
                for (let d of data) {
                    if (d.member_lesson_records && d.member_lesson_records.length > 0) {
                        for (let r of d.member_lesson_records) {
                            if (r.fee_status > 1) {
                                settledFee.current += Number(r.fee)
                                dividedFee.current += Number(r.fee) * Number(r.divided_rate)
                            }
                        }
                    }
                }
                onLoaded && onLoaded({ settledFee: settledFee.current, dividedFee: dividedFee.current })
            }, setError)
        })
    }
    useEffect(() => {
        if (initPage.current) {
            return
        }
        initPage.current = true
        loadDatas()
    }, [])
    useEffect(() => { setAllSchedule(listAll == true ? true : false) }, [listAll])
    const handleTeacherCheckin = (id, tid) => {
        apis.teacherCheckin(id, tid).then(ret => {
            apiResult(ret, data => {
                loadDatas()
            }, setError)
        })
    }
    const handleStudentCheckin = (id, kid) => {
        apis.studentCheckin(id, kid).then(ret => {
            apiResult(ret, data => {
                loadDatas()
            }, setError)
        })
    }
    const handleStudentSettle = (id, idx) => {
        apis.classLessonSettle(id).then(ret => {
            apiResult(ret, data => {
                loadDatas()
            }, setError)
        })
    }
    const handleModifySchedule = (item) => {
        setEditSchedule(item)
    }
    const handleEditSchedule = (data) => {
        apis.classschedulePut(data).then(ret => {
            apiResult(ret, data => {
                // loadSchedule(field)
                setEditSchedule()
                loadDatas()
            }, (err) => { alert(err) })
        })
    }
    return <Box sx={{ width: '100%' }}>
        {datas && datas.map((item, idx) => {
            if (allSchedule || new Date(item.sdate) <= new Date()) {
                return <ScheduleItem key={idx} members={members} item={item} onModifySchedule={() => handleModifySchedule(item)} onStudentSettle={(id) => { handleStudentSettle(id, idx) }} onStudentCheckin={handleStudentCheckin} onTeacherCheckin={handleTeacherCheckin} />
            }
        })}
        {error && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={error ? true : false} autoHideDuration={3000} onClose={() => setError()}>
            <Alert onClose={() => setError()} severity="warning" sx={{ width: '100%' }}>{error}</Alert>
        </Snackbar>}
        {editSchedule && <ScheduleStatusDialog schedule={editSchedule} onClose={() => { setEditSchedule() }} onSubmit={handleEditSchedule} />}
    </Box>
}
export default ClassMemberRecords