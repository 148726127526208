import { Box, Checkbox, Button,FormControlLabel, TextField,Typography, Alert, Grid, } from "@mui/material"
import { useEffect, useState } from "react"
import { hex_md5 } from "react-native-md5"
import apis from "../../../api"
import { loadAreas } from "../../../Component/DataLoader"
import { MultiSelector, PasswordTextField, SingleSelector } from "../../../Component/MuiEx"
import { apiResult,  getUserSession } from "../../../Utils/Common"

const MemberEditForm = (props) => {       
    const [error,setError] = useState()
    getUserSession(apis)
    const [data,setData] = useState()    
    const [struct,setStruct] = useState()    
    useEffect(() => {
        //loadAreas(apis,setAreas,setError)
        apis.getCustomer(props.id?props.id:0).then(ret => {
            apiResult(ret, (data) => {
                if(data.info) {
                    data.info.map(item=>{
                        data.member[item['key']] = item['value']
                        return true
                    })
                }                
                setData(data.member)
                setStruct(data.struct)
            }, setError)
        })
        return
    }, [])

    const onChange=(key,value,ispass=false)=>{  
        setError()        
        if(ispass) {            
            value = value.length>0?hex_md5(value):undefined
        }
        if(!ispass || value != '') {        
            setData({...data,[key]:value})
        }
    }
    const handleSave = ()=>{    
        Object.keys(data).map(key=>{
            if(key !== 'id' && (!data[key] || data[key] == null)) {
                delete data[key]
            }
            return true
        })
        if(props.user_id>0) 
            data.user_id = props.user_id
        apis.editCustomer(data).then(ret=>{
            apiResult(ret,id=>{                
                if(data.id === 0) {
                    let dd = {...data}
                    dd.id = id               
                    setData(dd)
                    props.onClose && props.onClose(true)
                }else {
                    props.onClose && props.onClose(true)
                }
            },setError)
        })
    }
    return data && <Box sx={{ mt: 10 ,p:2,maxWidth:'500px'}}>
        {props.title && <Typography variant="h4">{props.title}</Typography>}
        <Grid container spacing={2}>
            
        {struct.map((section, index) => {            
            return section.items.map((subitem, index1) => {     
                let obj = <></>           
                if (subitem.type === 'text') {
                    obj = <Typography key={index1} component="div" variant="body1" ><div style={{ width: "100%", }} dangerouslySetInnerHTML={{ __html: subitem.label }}></div></Typography>
                } else if (subitem.type === 'input') {
                    obj = <TextField key={index1} margin="normal" fullWidth label={subitem.label} value={data[subitem.name]} onChange={(e) => { onChange && onChange(subitem.name, e.target.value) }} />
                }  else if (subitem.type === 'password') {
                    obj = <PasswordTextField key={index1} sx={{width:'100%',marginTop:2,marginBottom:1}} fullWidth label={subitem.label} onChange={(e) => { onChange && onChange(subitem.name, e.target.value,true) }} />
                    //return <TextField key={index1} margin="normal" type="password" fullWidth label={subitem.label} onChange={(e) => { onChange && onChange(subitem.name, e.target.value,true) }} />
                } else if (subitem.type === 'check') {
                    obj =  <FormControlLabel key={index1} control={<Checkbox color="primary" defaultChecked={data[subitem.name]} onChange={(e) => { onChange && onChange(subitem.name, !data[subitem.name]) }} />} label={subitem.label} />
                } else if (subitem.type === "option") {
                    obj =  <MultiSelector key={index1} label={subitem.label} items={subitem.options ? subitem.options.split(',') : []} values={subitem.options ? subitem.options.split(',') : []} defaultValue={data[subitem.name]} onChange={(name, value) => { onChange && onChange(subitem.name, value.toString()) }} />
                } else if (subitem.type === 'radio') {
                    obj =  <SingleSelector key={index1} label={subitem.label} items={subitem.options ? subitem.options.split(',') : []} values={subitem.options ? subitem.options.split(',') : []} defaultValue={data[subitem.name]} onChange={(name, value) => { onChange && onChange(subitem.name, value) }} />
                } else if (subitem.type === 'date') {
                    obj =  <TextField fullWidth key={index1}
                                margin="normal" type="date"
                                onChange={(e) => { onChange && onChange(subitem.name,e.target.value) }}
                                value={data[subitem.name]} label={subitem.label}
                                InputLabelProps={{ shrink: true }} />                    
                }
                return <Grid item xs={subitem.type == 'option' || subitem.type == 'radio'?12:6}>{obj}</Grid>
            })
        })}        
        {error && <Grid item xs={12}><Alert severity="error">{error}</Alert></Grid>}
        <Grid item xs={6}><Button variant="contained" fullWidth onClick={handleSave}>Save</Button></Grid>
        <Grid item xs={6}><Button type="button" fullWidth variant="outlined" onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button></Grid>
        </Grid>
    </Box>
}
export default MemberEditForm