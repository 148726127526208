import { useState } from "react"
import { Box, TextField, Stack, Paper, Typography } from "@mui/material"
import Uploader from "../../../Component/Uploader";
const UploaderItem = (props)=>{
    const {label,name,value,onChange} = props
    const [_value,setValue] = useState(value)
    return <Box sx={{ width: '100%' }}>
    {_value && _value.length > 0 && <img src={_value.substring(0, 4) === 'http' ? _value : ('/api/resource/photo?file=' + _value)} style={{ maxWidth: '100%', height: '200px' }} />}
    <Stack direction="row">
        <TextField label={label} placeholder="Copy picture url to here or upload picture" onChange={(e) => { setValue(e.target.value);onChange && onChange(name, e.target.value) }} fullWidth defaultValue={_value && _value.substring(0, 4) === 'http' ? _value : _value} />
        <Uploader onUpload={(f) => { setValue(f); onChange && onChange(name, f) }} />
    </Stack>
</Box>
}
export default UploaderItem