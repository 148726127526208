import MyTable from "../../Component/MyTable"
import { TextField, FormControl, Alert, Paper, Toolbar, Typography, Button, Stack, Snackbar, Backdrop, Chip, ButtonGroup, Box, IconButton, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { apiResult, formatDate, getCurrentMonth01, getUserSession, } from "../../Utils/Common";
import apis from "../../api";
import { RightDrawer, } from "../../Component/MuiEx";

import { Navigate, useNavigate, useParams } from "react-router";
import { LOGO_TEXT_COLOR } from "../../App/config";
import { Mood, MoodBad, Payment, People, PriceCheck } from "@mui/icons-material";
import { MemberSettleType } from "../../Utils/Dict";
const LessonRecords = () => {
    const params = useParams()
    const [hintMsg, setHintMsg] = useState()
    const memberid = useRef(params.memberid)
    const [datas, setDatas] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [title, setTitle] = useState()
    const [error, setError] = useState()
    const [fields, setFields] = useState({ begin: getCurrentMonth01(), end: formatDate(new Date()), action: 'all' })
    const [rightDrawer, setRightDrawer] = useState()   //open or close right drawer
    const [autoCloseRightDrawer, setAutoCloseRightDrawer] = useState(true)
    const [totalFee,setTotalFee] = useState(0)
    const [showFee,setShowFee] = useState(false)
    const navigate = useNavigate()
    getUserSession(apis)
    // const navigate = useNavigate()

    const handleHintClose = () => {
        setHintMsg()
    }
    const loadData = (page, pagesize, countData) => {
        //setLoading(true)
        setError()        
        apis.lessonlog(memberid.current, page, pagesize, countData, fields.begin, fields.end).then(ret => {
            apiResult(ret, (data) => {
                countData == 1 && setTotalCount(data.total)
                countData == 1 && setTotalFee(data.totalFee)
                
                setDatas(data.data)
                if (data.data.length > 0) {
                    let title = memberid.current > 0 ? data.data[0].member.name : 'All members'
                    setTitle(title)
                }
                setCurrentPage(page)
            }, setError)
        })
    }

    const initPage = useRef(false)
    useEffect(() => {
        if (initPage.current) return
        initPage.current = true
        loadData(0, rowsPerPage, 1)
    }, [params])

    const handleRightDrawer = () => {
        autoCloseRightDrawer && setRightDrawer(false)
    }

    const handleChangePage = (page, rowsperpage) => {
        loadData(page, rowsperpage, 0)
    }
    const handleChangeRowsPerPage = (rowsperpage) => {
        setRowsPerPage(rowsperpage)
        setError()
        loadData(0, rowsperpage, 0)
    }
    const handleEdit = (id, idx) => {
        const order = datas[idx]
        // setRightDrawer(<EditTransaction transaction={order} onClose={(ret) => {
        //     setRightDrawer();
        //     if (ret) {
        //         const tt = [...datas]
        //         tt[idx] = { ...tt[idx], ...ret }
        //         if (ret.order_date && tt[idx].order) {
        //             tt[idx].order.order_date = ret.order_date
        //         }
        //         if (ret.peoples && tt[idx].order) {
        //             tt[idx].order.peoples = ret.peoples
        //         }
        //         setDatas(tt)
        //     }
        // }} />)
    }
    const handleChange = (key, v) => {
        setFields({ ...fields, [key]: v })
    }
    const handleGo = () => {
        loadData(0, rowsPerPage, 1)
    }
    
    const TableHeader = [
        { name: 'id', showName: 'No.' },
        {
            name: 'member_id', showName: 'Member', func: (v, idx, row) => {
                return <Box sx={{ color: LOGO_TEXT_COLOR, cursor: 'pointer' }} onClick={() => {
                    if(memberid.current == row.member_id) return
                    memberid.current = row.member_id
                    loadData(0, rowsPerPage, 1)
                    navigate('/member/lessonlog/' + memberid.current)
                }}>{row.member?.name}</Box>
            }
        },
        { name: 'lesson_date', showName: 'Date' },
        {
            name: 'class_id', showName: 'Class', func: (v, idx, row) => {
                return row.class_id > 0 && <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{
                    navigate('/class/class/'+row.class_id)
                }}>{row.class?.name}</Box>
            }
        },
        {
            name: 'teacher_id', showName: 'Teacher', func: (v, idx, row) => {
                return row.teacher_id > 0 && <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{
                    navigate('/coach/lessonlog/'+row.teacher_id)
                }}>{row.Teacher?.name}</Box>
            }
        },
        {
            name: 'assistant_id', showName: 'Assistant', func: (v, idx, row) => {
                return row.assistant_id > 0 && <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{
                    navigate('/coach/lessonlog/'+row.assistant_id)
                }}>{row.Assistant?.name}</Box>
            }
        },
        {
            name: 'course_id', showName: 'Course', func: (v, idx, row) => {
                return row.course_id > 0 && <Box sx={{cursor:'pointer',color:LOGO_TEXT_COLOR}} onClick={()=>{
                    navigate('/lesson/course/'+row.course_id)
                }}>{row.course?.name}</Box>
            }
        },
        {
            name: 'lesson_id', showName: 'Lesson', func: (v, idx, row) => {
                return row.lesson_id > 0 ?(row.lessons && row.lessons.length > 0 ? (row.lesson_id+":"+row.lessons[0].name) : ("Lesson "+row.lesson_id)):''
            }
        },
        { name: 'points', showName: 'Points' },
        {
            name: 'comment', showName: 'Comment', func: (v, idx, row) => {
                return <ButtonGroup size="small">
                    <Button title="Comprehension">{row.score1}</Button>
                    <Button title="Focus">{row.score2}</Button>
                    <Button title="Participation">{row.score3}</Button>
                    <Button title="Discipline">{row.score4}</Button>
                    <Button title="Homework">{row.score5}</Button>
                    {row.score6 > 0 && <Button title="Typing speeding">{row.score6}</Button>}
                    {row.comment && row.comment.length > 0 && <Button title="Comment" onClick={() => { alert(row.comment) }}>...</Button>}
                </ButtonGroup>
            }
        },
        { name: 'checkin', showName: 'Attend',func:(v,idx,item)=>{
            return v==1?<Mood color="info" />:<MoodBad />
        } },
        showFee && { name: 'fee', showName: 'Settled Fee',func:(v,idx,item)=>{
            return item.fee_status>1?<IconButton sx={{fontSize:'16px',color:LOGO_TEXT_COLOR}}><PriceCheck />{v}</IconButton>:''
        } },
        showFee && { name: 'divided_rate', showName: 'Divided Rate'},
        showFee && { name: 'fee_status', showName: 'Settle Status',func:(v,idx,item)=>{
            return MemberSettleType[v]
        } },
    ]

    return (
        <>
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <Paper sx={{ marginBottom: 2, p: 1 }}>
                <Toolbar style={{ paddingLeft: 2 }}>
                    <Typography variant="h6" sx={{ ml: 1 }} component="div" >Lesson records of {title}</Typography>
                    
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    {/* <Button onClick={()=>{navigate('/accounting/datas/'+params.id+'/'+(params.kid?params.kid:0))}}>switch</Button>               */}
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" sx={{ mt: "8px" }} type="date" name="begin" onChange={(e) => handleChange('begin', e.target.value)} value={fields.begin} id="begin" label="begin"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <FormControl sx={{ width: '180px', mr: 2 }}>
                        <TextField margin="normal" type="date" sx={{ mt: "8px" }} name="end" onChange={(e) => handleChange('end', e.target.value)} value={fields.end} id="end" label="end"
                            InputLabelProps={{ shrink: true }} />
                    </FormControl>
                    <Button variant='contained' onClick={handleGo}>Go</Button>
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    <IconButton onClick={()=>setShowFee(!showFee)}><PriceCheck />{showFee?Number(totalFee).toFixed(2):'......'}</IconButton>
                    <Typography sx={{ flex: '1 1 5%' }} variant="h6" component="div" ></Typography>
                    {memberid.current > 0 && <IconButton onClick={() => {
                        memberid.current = 0
                        loadData(0, rowsPerPage, 1)
                        navigate('/member/lessonlog/0')
                    }} variant="default"><People /></IconButton>}
                </Toolbar>
            </Paper>
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any datas</Alert>}
            {totalCount > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={totalCount}
                headers={TableHeader}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </>

    )
}
export default LessonRecords