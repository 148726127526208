import MyTable from "../../Component/MyTable"
import { Alert, Button, IconButton, Chip, Snackbar, Backdrop, Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Add, Receipt } from '@mui/icons-material'
import { apiResult, getUserSession, sessionSet, } from "../../Utils/Common";
import apis from "../../api";
import { RightDrawer } from "../../Component/MuiEx";
import Recharge from "./fragement/NewRecharge";
import { useNavigate } from "react-router";
import FamilyOptButton from "./fragement/FamilyOptButton";
import { FamilyManagerBar } from "./fragement/FamilyManagerBar";
import MemberEditForm from "./fragement/MemberEditForm";
import UserEditForm from "./fragement/UserEditForm";
import { useRef } from "react";
import { LOGO_TEXT_COLOR } from "../../App/config";
const FamilyManager = () => {
    const [loading, setLoading] = useState(false)
    const [hintMsg, setHintMsg] = useState()
    const [customers, setCustomers] = useState([])
    const [filters, setFilters] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [currentQuery, setCurrentQuery] = useState()
    const balanceTypes = useRef([])
    //const [balances,setBalances] = useState(0)    
    const [error, setError] = useState()
    //const [rightComponent, setRightComponent] = useState()   //right drawer children
    const [rightDrawer, setRightDrawer] = useState()   //open or close right drawer
    const [autoCloseRightDrawer, setAutoCloseRightDrawer] = useState(true)
    const navigate = useNavigate()
    const session = getUserSession(apis)
    const [header, setHeader] = useState([
        { name: 'id', showName: 'ID' },
        { name: 'parent', showName: 'Family' },
        { name: 'name', showName: 'Member' },
    ])
    const handleOrder = (item) => {
        const neworder = orderField.order == 'desc' ? 'asc' : 'desc'
        setOrderField({ ...orderField, name: item.name, order: neworder })
        currentQuery && currentQuery.action == 'loadMembers' && loadData(0, rowsPerPage, item.name, neworder)
    }
    const [orderField, setOrderField] = useState({ fields: ['id', 'parent', 'balance'], name: 'id', order: 'desc' })
    const handleHintClose = () => {
        setHintMsg()
    }

    const loadMembers = (page, pagesize, countData, field, order,value) => {
        setLoading(true)
        apis.loadFamilys(page, pagesize, countData, field, order,value).then(ret => {
            setLoading(false)
            apiResult(ret, (data) => {
                countData == 1 && setTotalCount(data.total)
                setCustomerData(data.data, data.members, data.balances)
                //setBalances(data.balances)
                value ? setCurrentQuery({ action: 'searchCustomers', p1: value }):setCurrentQuery({ action: 'loadMembers' })
                value && setFilters(<Chip sx={{ marginBottom: 1 }} label={value} variant="outlined" onDelete={() => { setFilters(); loadMembers(0, rowsPerPage, 1) }} />)
                setCurrentPage(page)
            }, setError)
        })
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        apis.balanceGet(0).then(ret=>{
            apiResult(ret,data=>{
                balanceTypes.current = data
                loadMembers(0, rowsPerPage, 1, 'id', 'desc')
            },setError)
        })
        
    }, [])
    const getBalanceType = (id)=>{
        for(let b of balanceTypes.current){
            if(b.id == id) {
                return b.type
            }
        }
    }
    const handleRightDrawer = () => {
        autoCloseRightDrawer && setRightDrawer(false)
    }
    const handleChangePage = (page, rowsperpage) => {
        //setRowsPerPage(rowsperpage)
        loadData(page, rowsperpage, orderField.name, orderField.order)
    }
    const handleChangeRowsPerPage = (rowsperpage) => {
        setRowsPerPage(rowsperpage)
        setError()
        loadData(0, rowsperpage, orderField.name, orderField.order)
    }
    const loadData = (page, rowsperpage, field, order) => {
        currentQuery && currentQuery.action == 'loadMembers' && loadMembers(page, rowsperpage, 0, field, order)
        currentQuery && currentQuery.action == 'searchCustomers' && searchCustomers(currentQuery.p1, page, rowsperpage, 0)
    }
    const handleAddKid = (id) => {
        setRightDrawer(<MemberEditForm user_id={id} onClose={(ret) => {
            setRightDrawer()
            if (ret) {
                sessionSet('member_list', null)
                setHintMsg("Succeed to add kid")
                loadData(currentPage, rowsPerPage, orderField.name, orderField.order)
            }
        }} />)
    }
    const handleAddFamily = () => {
        setRightDrawer(<UserEditForm onClose={(ret) => {
            setRightDrawer()
            if (ret) {
                setHintMsg("Succeed to add family")
                loadData(currentPage, rowsPerPage, orderField.name, orderField.order)
            }
        }} />)
    }
    const handleRecharge = (id, idx) => {
        setRightDrawer(<Recharge user_id={id} balanceTypes={balanceTypes.current} action="recharge" onClose={(ret) => { setRightDrawer(); ret && loadData(currentPage, rowsPerPage, orderField.name, orderField.order) }} />)
    }
    const handleRefund = (id, idx) => {
        setRightDrawer(<Recharge user_id={id} balanceTypes={balanceTypes.current} action="refund" onClose={(ret) => { setRightDrawer(); ret && loadData(currentPage, rowsPerPage, orderField.name, orderField.order) }} />)
    }

    const setCustomerData = (data) => {
        const items = []
        const hh = [
            { name: 'id', showName: 'Family ID' },
            {
                name: 'parent', showName: 'Parent', func: (v, idx, row) => {
                    return <Stack direction="row">
                        <Box sx={{color:LOGO_TEXT_COLOR, cursor: 'pointer' }} onClick={() => {
                            setRightDrawer(<UserEditForm user={row} onClose={() => setRightDrawer()} />)
                        }}>{v}</Box>
                        <IconButton size="small" sx={{mt:-0.5}} onClick={()=>{window.open("/member/balancelog/"+row.id+"/0")}}><Receipt fontSize="10px"/></IconButton>
                        </Stack>
                }
            },
            {
                name: 'members', showName: 'Children', func: (v, idx, row) => {
                    if (v && v.length > 0) {
                        return <div>
                            {v.map((k, i) => {
                                return <Chip label={String(k.id)+"."+k.name} sx={{ m: "2px",color:LOGO_TEXT_COLOR, borderColor: '#0001' }} size="small" variant="outlined" onClick={() => {
                                    setRightDrawer(<MemberEditForm id={k.id} user_id={row.id} title="Edit & Add Member" onClose={() => setRightDrawer()} />)
                                }} key={i}></Chip>
                            })}
                            <IconButton onClick={() => handleAddKid(row.id)}><Add fontSize={"small"} /></IconButton>
                        </div>
                    } else {
                        return <IconButton onClick={() => handleAddKid(row.id)}><Add fontSize={"small"} /></IconButton>
                    }
                }
            },
        ]
        data.map(item => {
            let d = { id: item.id, area_id:item.area_id,
                    parent: item.user_profile.name, 
                    user_id: item.user_profile.user_id, 
                    phone: item.user_profile.phone,
                    email:item.user_profile.email,
                    firstname:item.user_profile.firstname,
                    lastname:item.user_profile.lastname,                    
                    members: []}
            item.members.map(b => {                
                d.members.push({ id: b.id, name: b.name, status: b.status})
            })
            item.user_balances.map(b => {
                    d[b.balance_typeid] = b.balance
                    let h = []
                    hh.map(item => {
                        if (item.name == b.balance_typeid) h.push(item)
                    })
                    if (h.length == 0) {
                        hh.push({
                            name: b.balance_typeid, showName: getBalanceType(b.balance_typeid), func: (v) => {
                                if (Number(v) < 100) {
                                    return <span style={{ color: 'red' }}>{v}</span>
                                } else {
                                    return <span style={{ color: 'green' }}>{v}</span>
                                }
                            }
                        })
                    }
                
            })
            items.push(d)
        })
        setHeader(hh)
        setCustomers(items)
    }
    const searchCustomers = (value, page, rowperpage, countdata) => {
        setLoading(true)       
        loadMembers(page,rowperpage,countdata,orderField.name, orderField.order,value)
    }
    const handleCustomerSearch = (event) => {
        setError()
        event.preventDefault();
        const postdata = new FormData(event.currentTarget);
        searchCustomers(postdata.get('value'), 0, rowsPerPage, 1, orderField.name, orderField.order)
    }


    return (
        <>
            <Backdrop open={loading} />
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <FamilyManagerBar
                onSearch={handleCustomerSearch}
                onAdd={handleAddFamily}
            />
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any familys or members</Alert>}
            {filters}
            {totalCount > 0 && <MyTable
                height={650}
                rows={customers}
                totalRow={totalCount}
                headers={header}
                order={orderField}
                onOrder={handleOrder}
                checkbox={false}
                rowsPerPage={rowsPerPage}
                OpentionComponent={(id, idx) => {
                    return <FamilyOptButton
                        onRecharge={(id, idx) => handleRecharge(id, idx)}
                        onRefund={(id, idx) => handleRefund(id, idx)}
                        id={id} index={idx} />
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
        </>

    )
}
export default FamilyManager